<template>
  <!-- 弹窗-->
  <el-dialog
      title="选择部门或成员"
      :visible.sync="_show"
      width="50%"
      class="dep-visible"
      style="min-width: 400px;"
  >
    <div class="wrapper">
      <div class="left">
        <el-autocomplete
            placeholder="请输入关键词"
            :fetch-suggestions="remoteMethod"
            v-model="keyword"
            @select="handleSelect"
            style="width: 100%"
        >
          <template slot-scope="{ item }">
            <div class="tree-item" >
              <span>
                <el-avatar v-if="item.is_user" :src="item.avatar" :size="20"></el-avatar>
                 <i v-else class="el-icon-folder"></i>
              </span>

              <span v-if="allIsWx3=='true'">
                <span class="tree-title" v-if="item.is_user">
                  <wxComponent type="userName" :openid="item.dep_id"/> | <wxComponent type="departmentName" :openid="item.main_department"/>
                </span>
                <span class="tree-title" v-else>
                  <span v-if="item.dep_id===9999999">{{ item.dep_name }}</span>
                  <wxComponent v-else type="departmentName" :openid="item.dep_id"/>
                </span>
              </span>
              <span v-else>
                <span class="tree-title" v-if="item.is_user">
                  <span>{{item.dep_name}}</span> | <span>{{item.user_depart}}</span>
                </span>
                <span class="tree-title" v-else>
                  <span>{{item.dep_name}}</span>
                </span>
              </span>

            </div>
          </template>

        </el-autocomplete>
        <el-scrollbar >
          <el-tree
              v-infinite-scroll="scrollLoad"
              :load="loadNode"
              lazy
              @node-expand="getBarThis"
              :props="props"
              show-checkbox
              @check="check"
              ref="tree"
              :check-strictly="true"
              :default-expanded-keys="defaultExpend"
              node-key="dep_id"

          >
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                             <div class="tree-item">
                                <span>
                                      <el-avatar v-if="node.data.is_user" :src="node.data.avatar"
                                                 :size="20"></el-avatar>
                                   <i v-else class="el-icon-folder"></i>
                                </span>
                                <span class="tree-title" v-if="data.is_user">
                                  <wxComponent v-if="allIsWx3=='true'" type="userName" :openid="data.dep_id"/>
                                  <span v-else>{{data.dep_name}}</span>
                                </span>
                                <span class="tree-title" v-else>
                                  <span v-if="allIsWx3=='false'">{{ data.dep_name }}</span>
                                  <span v-else-if="allIsWx3=='true' && data.dep_id===9999999">{{ data.dep_name }}</span>
                                  <wxComponent v-else type="departmentName" :openid="data.dep_id"/>
                                </span>
                             </div>
                        </span>
          </el-tree>
        </el-scrollbar>

      </div>
      <div class="right">
        <div class="right-title">
          <span>已选择</span>
          <el-button size="mini" @click="clearAll">清空</el-button>
        </div>
        <div class="user-list">
          <el-scrollbar>
            <div class="user-item" v-for="(item,index) in userList" :key="item.dep_id">
                        <span>
                             <span v-if="item.is_user">
                              <el-avatar :src="item.avatar" :size="20"></el-avatar>
                        </span>
                        <i v-else class="el-icon-folder"></i>
                        </span>
              <span class="user-title" v-if="item.is_user">
                <span v-if="allIsWx3=='true'">
                  <wxComponent  type="userName" :openid="item.dep_id"/> | <wxComponent type="departmentName" :openid="item.main_department"/>
                </span>
                <span v-else>
                  <span>{{item.dep_name}}</span> | <span>{{item.user_depart}}</span>
                </span>
              </span>
              <span class="user-title" v-else>
                <span v-if="allIsWx3=='false'">{{item.dep_name}}</span>
                  <span v-if="item.dep_id===9999999 && allIsWx3=='true'">{{ item.dep_name }}</span>
                  <wxComponent v-else type="departmentName" :openid="item.dep_id"/>
                </span>
              <i @click="deleted(item,index)" class="el-icon-close" style="cursor: pointer"></i>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
     <el-button @click="refuse">取 消</el-button>
    <el-button type="primary" @click="submit()">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {getDepart,checkDeptUser} from "@/api/workbench";
import wxComponent from "@/components/wx";

import {getConfigApi} from "@/api/config";
// import {otherBrowser, wxAgentConfig, wxConfig} from "@/utils/wx";

export default {
  name: "pickDepart",
  props: ["show"],
  watch: {
    // keyword(val) {
    //   this.$refs.tree.filter(val);
    // },
    // _show(val) {
    //   this.showAll(val);
    //   this.$nextTick().then(() => {
    //     //更新选中状态到Tree组件
    //     this.$refs["tree"].setCheckedKeys(this.ids);
    //   });
    // }
  },
  components: {
    wxComponent
  },
  computed: {
    _show: {
      get: function () {
        return this.show;
      },
      set: function (val) {
        this.$emit("update:show", val);
      }
    },
    filtterList() {//所有的相同的人
      return function (data) {
        return this.selectUserList.filter(item => data.userid === item.userid)
      }
    },
  },
  data() {
    return {
      deleteShow: "", //删除按钮显示隐藏
      visible: true, //弹窗显示隐藏
      data: [], //节点数据
      props: {
        isLeaf: 'is_user',
        label: 'dep_name',
      },
      userList: [], //
      userList__: [],
      selectUserList: [],//所有无序选人
      keyword: "", //搜索部门、成员关键词
      name: "", //已选择的部门、人员
      ids: [],
      openDepId:0,//当前展开的节点id
      page:1,
      openData:{},
      depIndex: 0,
      dep: [],
      editIds:[],
      defaultExpend:[],
      allIsWx3:true,
    };
  },
  created() {
    this.allIsWx3=sessionStorage.getItem('is_wx3')
  },
  // async mounted() {
  //   if(this.allIsWx3==='true'){
  //     if (process.env.NODE_ENV === 'production') {
  //       if (/MicroMessenger/i.test(navigator.userAgent)) {
  //         const {data: getConf} = await getConfigApi({
  //           type: 1,
  //           current_url: window.location.origin + window.location.pathname
  //         })

  //         const getConfig = {
  //           beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           appId: getConf.corpid, // 必填，企业微信的corpID
  //           timestamp: getConf.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: getConf.noncestr, // 必填，生成签名的随机串
  //           signature: getConf.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
  //           jsApiList: ['WWOpenData'],
  //         }
  //         await wxConfig(getConfig)
  //         const {data} = await getConfigApi({type: 2, current_url: window.location.origin + window.location.pathname})

  //         const config = {
  //           beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
  //           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  //           corpid: data.corpid, // 必填，企业微信的corpID
  //           agentid: data.agentid,
  //           timestamp: data.timestamp, // 必填，生成签名的时间戳
  //           nonceStr: data.noncestr, // 必填，生成签名的随机串
  //           signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
  //           jsApiList: ['WWOpenData'],
  //         }

  //         wxAgentConfig(config).then(_ => {
  //           // eslint-disable-next-line no-undef
  //           WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
  //         })


  //       } else {
  //         otherBrowser()
  //       }
  //     }
  //   }
  // },
  methods: {
    getBarThis(node,data){
      this.openDepId=node.dep_id;
      this.openData=node;
      this.page=1;
      // this.editIds.map(item=>this.$refs.tree.setChecked(item,true ))
    },
    getUserApi(data) {
      return getDepart({dep_id: data, get_user: 1,page:1,limit:20}).then(res => {
        let data = [];
        res.data.depart.map(item => {
          data.push({
            dep_id: item.dep_id,
            dep_name: item.dep_name,
            pid: item.pid,
            sort: item.sort,
            target: item.dep_id,
            deleted: item.deleted,
            userid: item.dep_id
          })
        })
        res.data.user.map(item => {
          data.push({
            dep_id: item.userid,
            userid: item.userid,
            target: item.userid + item.open_userid,
            open_userid: item.open_userid,
            dep_name: item.name,
            avatar: item.avatar,
            is_user: true,
            main_department:item.main_department,
            user_depart:item.user_depart?item.user_depart:null ,
          })
        })
        this.selectUserList = this.selectUserList.concat(data)
        return data
      })
    },
    loadNode(node, resolve) {
      let temList = []

      if (node.level === 0) {
        this.getUserApi(0).then(res => resolve(res))
      }

      if (node.level >= 1) {
        this.getUserApi(node.data.dep_id).then(res => {
          temList = res
          return resolve(res);

        }).then(() => {
          this.$nextTick(() => {
            temList = temList.filter(item => this.userList.some(chindren => item.userid == chindren.userid))
            this.setNode(temList, true)
          })
        })
      }
    },
    check(data) {
      const index = this.userList.findIndex(item => item.userid == data.userid)  //找到所选人里面有没有这个人
      if (index < 0) {
        data.is_user ? this.userList.push(data) : this.userList.unshift(data)
      } else {
        this.userList.splice(index, 1);
      }
      this.setNode(this.filtterList(data), index < 0)
    },
    remoteMethod(query, cb) {
      query !== '' ? getDepart({
        dep_id: 0,
        get_user: 1,
        keywords: query
      }).then(res => {
        const data=[]
        const department=res.data.depart
        department.map(item=>{
          data.push({
            dep_id: item.dep_id,
            dep_name: item.dep_name,
            pid: item.pid,
            sort: item.sort,
            target: item.dep_id,
            deleted: item.deleted,
            userid: item.dep_id
          })
        })
        const userList=res.data.user;
        userList.map(item=>{
          data.push({
            dep_id: item.userid,
            userid: item.userid,
            target: item.userid + item.open_userid,
            open_userid: item.open_userid,
            dep_name: item.name,
            avatar: item.avatar,
            is_user: true,
            main_department:item.main_department,
            user_depart:item.user_depart?item.user_depart:null,
          })
        })
        console.log(data,'xxxxxxxxx')
        cb(data)
      } ) : cb([])
    },
    scrollLoad () {//滚动加载
      this.page++;
      getDepart({dep_id: this.openDepId, get_user: 1,page:this.page,limit:20}).then(res => {
        let data = [];
        res.data.user.map(item => {
          data.push({
            dep_id: item.userid,
            userid: item.userid,
            target: item.userid + item.open_userid,
            open_userid: item.open_userid,
            dep_name: item.name,
            avatar: item.avatar,
            is_user: true,
            main_department:item.main_department,
            user_depart:item.user_depart?item.user_depart:null,
          })
        })
        this.selectUserList = this.selectUserList.concat(data)
        if(data.length>0){
          data.map(item=>{
            this.$refs.tree.append(item,this.openData)
            WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
          })

        }

      })
    },

    deleted(item, index) { //删除人
      const list = this.filtterList(item)
      this.setNode(list, false)
      this.userList.splice(index, 1)
    },
    setNode(list, checked) {
      list = list.map(item => this.$refs.tree.setChecked(item.dep_id, checked))
    },
    handleSelect(item) {
      this.check(item)
    },
    clearAll() { //清空
      const temList = this.selectUserList.filter(item => this.userList.some(children => item.userid == children.userid))
      this.setNode(temList, false)
      this.userList = []
    },
    refuse() {
      this._show = false
      let list = []
      this.userList__.map(item =>
          list = list.concat(this.filtterList(item))
      )
      this.$refs['tree'].setCheckedKeys(list.map(item => item.dep_id))
      this.userList = [...this.userList__]
      this.keyword = ''
    },
    submit() {
      this.userList__ = [...this.userList]  //上次所选的人
      this.$emit("addUsers", this.userList);
      this._show = false;
    },
    chooseType(ids,department,user) {
      console.log(ids,department,user)
      this.editIds=ids;
      if(this.$refs.tree){
        ids.map(item => this.$refs.tree.setChecked(item,true ))
      }else{
        this.defaultExpend=[1];
        checkDeptUser({type:3,department:department,user:user}).then(res=>{
          let data=res.data;
          data.department.map(item => {
            this.userList.push({
              dep_id: item.dep_id,
              dep_name: item.dep_name,
              pid: item.pid,
              sort: item.sort,
              target: item.dep_id,
              deleted: item.deleted,
              userid: item.dep_id
            })
          })
          data.user.map(item => {
            this.userList.push({
              dep_id: item.userid,
              userid: item.userid,
              target: item.userid + item.open_userid,
              open_userid: item.open_userid,
              dep_name: item.name,
              avatar: item.avatar,
              is_user: true,
              main_department:item.main_department,
              user_depart:item.user_depart?item.user_depart:null,
            })
          })
        })
      }
    },


  }
};
</script>
<style lang="less" scoped>
.plr20 {
  padding: 20px;
}

.el-dialog {
  z-index: 999999 !important;

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog .el-dialog__header {
    background: rgba(0, 130, 239, 1);
    padding: 15px;

    .el-dialog__title {
      color: #fff;
    }
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    position: absolute;
    top: -10px;
    color: #fff;
  }

  /deep/ .el-tree-node__expand-icon.expanded {
    transform: none;
  }

  .userList {
    height: 300px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .Btn {
    padding: 9px 15px;
    text-align: right;
    border-top: 1px solid #ccc;
  }

  .flex_Between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input {
      width: 50%;
    }
  }

  .searchBtn {
    cursor: pointer;
  }

  .checkedBox {
    padding: 15px 0;
    height: 300px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;

    .checkedBoxList {
      padding: 6px 20px;
      cursor: pointer;
    }

    .checkedBoxList:hover {
      background: rgba(233, 242, 254, 1);
    }
  }
}
</style>