<template>
  <div>
    <div class="other-head">
      <div class="home-head flex-bet">
        <div class="head-title flex-start" @click="goToHome">
            <img src="@/assets/images/LOGO.svg" alt="logo" class="head-logo">
        </div>
        <div class="flex-start fz12 model-btns">
          <div class="cancel" @click="backToModel">返回</div>
          <div class="save" v-if="editType!=2" @click="saveModel">保存暂不启用</div>
          <div class="use" v-if="editType!=2" @click="submitModel">保存并启用</div>
        </div>
      </div>
    </div>
    <div class="main flex-bet-top" v-loading.fullscreen.lock="loading">
      <div v-show="openLeft" class="left-set">
        <div class="flex-end tabBanAll">
          <div class="tabBan">
            <div :class="{'active':tabType==1}" @click="ontabBan(1)">系统模块</div>
            <div :class="{'active':tabType==0}" @click="ontabBan(0)">自定义模块</div>
            <div :class="{'active':tabType==2}" @click="ontabBan(2)">模板信息</div>
          </div>
          <i class="el-icon-s-fold" style="cursor: pointer" @click="openLeft=false"></i>
        </div>
        <!-- <div :class="editType==2 ? 'mask' :''"></div> -->
        <!-- <div class="base-title">基本信息</div> -->
        <div class="MaskFather" v-show="tabType==2">
          <div :class="editType==2 ? 'mask-out' :''"></div>
          <el-form :model="ruleForm" class="left-form" 
            size="small"
            label-position="top"
            ref="ruleForm"
            label-width="100px"
          >
            <el-form-item label="自定义名称" prop="name">
              <el-input placeholder="请输入"  v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="模板备注" prop="remark">
              <el-input placeholder="请输入" type="textarea" :rows="3" v-model="ruleForm.remark"></el-input>
            </el-form-item>
            <div class="fz14">当推送对象里有重复用户时会覆盖推送内容</div>
          </el-form>
        </div>

        <div class="MaskFather" v-show="tabType!=2">
          <div class="search" v-show="status&&tabType==1">
            <el-input
              placeholder="请输入模块名称或描述"
              prefix-icon="el-icon-search"
              v-model="keyword"
              clearable
              @change="getSourceLists()"
              @clear="getSourceLists()"
            >
            </el-input>
            <el-popover
              placement="right-start"
              title="筛选模块"
              width="160"
              popper-class="screenLayer"
              >
              <div class="screenList">
                <div @click="api_tag_id='';getSourceLists()" :class="{'active':api_tag_id==''}">
                  全部 <img src="../../assets/images/xz.svg" v-show="api_tag_id==''" alt="">
                </div>
                <div v-for="(item,index) in api_tag_list" :key="index" @click="api_tag_id=item.api_tag_id;getSourceLists()" :class="{'active':api_tag_id==item.api_tag_id}" >
                  {{item.api_tag_name}}<img src="../../assets/images/xz.svg" v-show="api_tag_id==item.api_tag_id" alt="">
                </div>
              </div>
              <div class="screen" slot="reference">
                <img src="../../assets/images/sx.svg" alt="">
                筛选
              </div>
            </el-popover>
          </div>
          <div :class="editType==2 ? 'mask-out' :''"></div>
          <!-- <div class="base-title">设置模板</div> -->
          <div class="flex-cent-col" v-if="loadstate&&modelList.length==0">
            <img src="@/assets/images/noData.png" alt="暂无数据" style="margin:30px 0">
            <div style="color:#999;">暂无数据</div>
          </div>
          <div class="model-item fz12" v-for="(item,index) in modelList" :key="index">

            <div class="Default" @click="selectType(item)">
              <div class="model-title flex-start">
                <div style="font-size:12px;">{{item.name}}</div>
                <div :class="{'title-desc':tabType!=1}">{{item.desc}}</div>
                <div v-if="tabType==1" class="title-desc">{{item.function_name}}{{item.api_version!=''?'-'+item.api_version+'版本':''}}</div>
              </div>
              <div class="show-model-icon">
                <div class="top-app-name flex-start">
                  <img src="@/assets/images/showIcon.svg" alt="" class="four-icon">
                  <div class="show-icon-app-name">{{item.title}}</div>
                  <span class="el-icon-arrow-right"></span>
                </div>
                <div class="bot-main-cont flex-around" v-if="item.type=='keydata'">
                  <div v-for="index in 4" :key="index">
                    <div class="keydata-name" v-if="!item.access_token">示例{{index}}</div>
                    <div class="keydata-name" v-else>{{index==1?'待处理':index==2?'已处理':index==3?'我提交的':'抄送我的'}}</div>
                    <div class="keydata-value">8</div>
                  </div>
                </div>
                <div class="bot-main-cont" v-else-if="item.type=='image'" >
                  <img src="@/assets/images/showIcon2.jpg" alt="" style="width:100%;">
                </div>
                <div class="bot-main-cont2" v-else-if="item.type=='list'" >
                  <div class="list-name fz14" v-for="index in 3" :key="index">示例{{ index==1?'1':index==2?'2':'3' }}</div>
                </div>
                <!-- && item.webview_type=='list' -->
                <div class="bot-main-cont3" v-else-if="item.type=='webview' && item.wv_type == undefined  " >
                  <img src="@/assets/images/showIcon3.jpg" alt="" style="height:100%;">
                </div>
                <div class="bot-main-cont3" v-else-if="item.type=='webview' && item.wv_type=='list'  " >
                  <img src="@/assets/images/showIcon3.jpg" alt="" style="height:100%;">
                </div>
                <div class="bot-main-cont3" v-else-if="item.type=='webview' && item.wv_type=='carousel' "  >
                  <img src="@/assets/images/showIcon2.jpg" alt="" style="height:100%;">
                </div>
              </div>
            </div>

            <!-- <img :src="item.img" alt="模板" class="select-img" @click="selectType(item)"> -->
          </div>
        </div>
      </div>
      <div v-show="!openLeft" class="hide-left" @click="openLeft=true"><i class="el-icon-s-unfold"></i></div>
      <div class="middle-con flex-cent">
        <div class="push-menu fz12">
          <div class="menu-item" @click="getPushDetail(item.modulars,index)" v-for="(item,index) in pushList" :key="index">
            <div class="left-blue" :class="selectPushIndex===index?'push-active':''" v-if="item.obj_name=='全员推送'">
              <span>全员推送（默认）</span>
            </div>
            <div class="left-blue" :class="selectPushIndex===index?'push-active':''" v-else>
              <div class="obj-name">{{item.obj_name}}</div>
              <el-dropdown trigger="click" size="small" class="group-icon" @command="handleChange">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="beforeChange(item,'a',index)">修改推送名</el-dropdown-item>
                  <el-dropdown-item :command="beforeChange(item,'b',index)">修改推送对象</el-dropdown-item>
                  <el-dropdown-item :command="beforeChange(item,'c',index)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
<!--              <i @click="pickUser(item,index)" class="el-icon-edit"></i>-->
            </div>
          </div>
          <div @click="addPushItem" v-if="editType!=2" class="menu-items menu-add">新增</div>
        </div>
        <div class="split">
          <img src="@/assets/images/bg_top.png" alt="状态栏" class="bg-top">
          <div class="bg-show">
            <div class="no-data" v-if="showIcon.length===0">
              <img src="@/assets/images/move.png" alt="" class="no-data-icon">
              <div class="fz12">从左侧选择一个<span style="color:#78B9EF;">元素模板</span></div>
            </div>
            <div v-else class="show-model-list">
              <draggable
                class="list"
                delay="0"
                :no-transition-on-drag="true"
                @change="change"
                @start="start"
                @end="end"
                :move="move"
                v-model="showIcon"
              >
                <div class="show-item"  v-for="(icon,index) in showIcon" :key="index" @click="showModelDet(icon,index)">
                  <div v-if="editType==2">
                    <img :src="pushIcon(icon.modular_type,icon)"  @click="showModelDet(icon,index)" class="show-model-icon" :class="modularIndex===index?'modular-active':''">
                  </div>
                  <div v-else class="show-model-icon"  :class="modularIndex===index?'modular-active':''">
                    <div class="top-app-name flex-start">
                      <img src="@/assets/images/showIcon.svg" alt="" class="four-icon">
                      <div class="show-icon-app-name">{{icon.modular_name}}</div>
                    </div>
                    <div class="bot-main-cont flex-around" v-if="icon.modular_type=='keydata'">
                      <div v-for="(item,index) in keyDataShowIcon" :key="index">
                        <div class="keydata-name" v-if="icon.access_token=='mgtxapi_wxsp'">{{index==1?'待处理':index==2?'已处理':index==3?'我提交的':'抄送我的'}}</div>
                        <div class="keydata-name" v-else>{{ item.name }}</div>
                        <div class="keydata-value">{{item.value}}</div>
                      </div>
                    </div>
                    <div class="bot-main-cont" v-else-if="icon.modular_type=='image'" >
                      <img src="@/assets/images/showIcon2.jpg" alt="" style="width:100%;">
                    </div>
                    <div class="bot-main-cont2" v-else-if="icon.modular_type=='list'" >
                      <div v-for="(item,index) in listShowIcon" :key="index">
                        <div class="list-name fz14">{{ item.name }}</div>
                      </div>
                    </div>
                    <div class="bot-main-cont3"  v-else-if="icon.modular_type=='webview' && (icon.wv_type == '' || icon.wv_type == undefined )  " >
                      <img src="@/assets/images/showIcon3.jpg" alt="" style="height:100%;">
                    </div>
                    <div class="bot-main-cont3"  v-else-if="icon.modular_type=='webview' &&  icon.wv_type=='list' " >
                      <img src="@/assets/images/showIcon3.jpg" alt="" style="height:100%;">
                    </div>
                     <div class="bot-main-cont3"  v-else-if="icon.modular_type=='webview' &&  icon.wv_type=='carousel' " >
                      <img src="@/assets/images/showIcon2.jpg" alt="" style="height:100%;">
                    </div>
                  </div>
                  <div class="show-more" v-show="selectPushIndex===0 && editType!=2">
                    <el-dropdown trigger="click" size="medium" @command="handleCommand">
                        <span class="el-dropdown-link">
                          <i class="iconfont icon-19 "></i>
                        </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="beforeCom(icon,'a',index)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <img src="@/assets/images/bg_bot.png" alt="" class="bg-bot">
        </div>
      </div>
      <div class="right-set">
        <div :class="editType==2 ? 'mask' :''"></div>
        <modelComponent ref="setDetail" :apilistArr="apilist_arr" :pushList="pushList" :modularIndex='modularIndex' @setDetail="setDetail" :tabType="tabType"></modelComponent>
      </div>
    </div>

    <!-- 新建范围弹出框 -->
    <el-dialog title="" class="push-dialog" :visible.sync="dialogPush" width="320px">
      <div class="notice fz12">
        <div class="notice-title">推送范围</div>
        <div class="notice-con">设置后，会对设置范围内的人员，推送个人数据<br/>
          没设置的人员，仍然推送公司统一数据！</div>
        <div class="group-input">
          <el-input v-model="groupInput" placeholder="请输入推送对象群名"></el-input>
        </div>
        <div class="notice-add" @click="pickStuff()">+添加</div>
      </div>
    </el-dialog>
    <!-- 选择成员弹出框 -->
    <PickUser class="pick-user" ref="pickShare" :show.sync="showPickDir" @addUsers="addUsers" ></PickUser>

    <el-dialog
        title="确定启用并推送给员工吗？"
        :visible.sync="dialogVisible"
        width="30%">
      <span>推送成功后，员工的企业微信-工作台页面会自动刷新，数据推送可能会有延时</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="modelLoading" @click="getUseModel">启用并推送</el-button>
      </span>
      <!--    您可以去“<el-button type="text" @click="goToAsync">设置—任务列表</el-button>”中查看推送进度-->
    </el-dialog>

    <el-dialog title="" class="push-dialog" :visible.sync="editPush" width="320px">
      <div class="notice fz12">
        <div class="notice-title">修改推送对象群名</div>
        <div class="group-input" style="margin:10px 0;">
          <el-input v-model="editGroupInput" placeholder="请输入推送对象群名"></el-input>
        </div>
        <div class="flex-end"><el-button type="primary" size="small" @click="editGroupName">确定</el-button></div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import draggable from "vuedraggable" // 拖拽
import PickUser from "@/components/Depart";
import modelComponent from '@/components/NewModel';
import {modelDetail,newModel,editModel,sourceLists,getApiTagList} from '@/api/workbench'
export default {
  name: "Model",
  components:{
    PickUser,modelComponent,draggable
  },
  data(){
    return{
      tabType: 1, // 切换tab状态
      status: false, // 搜索状态
      loadstate: false, // 加载状态
      keyword: '', // 搜索值
      api_tag_list: [], // 标签列表
      api_tag_id: '', // 选中标签
      modelLoading:false,
      openLeft:true,
      id:null,
      editType:0,//编辑查看类型
      loading:false,
      editPush:false,
      editGroupInput:'',
      groupInput:'',//新建推送成员群名
      showPickDir: false, //选择成员弹出框
      ruleForm:{//基本信息
        name:'',
        remark:'',
        cover:2,
      },
      selectPushIndex:0,
      dialogPush:false,//新增推送范围
      modelLista:[//模板类型
        {name:'关键数据型',title:'应用名称',type:"keydata",desc:'适用于需要及时透露的关键数据',img:require('@/assets/images/model1.png')},
        {name:'图片型',title:'应用名称',type:"image",desc:'适用于通知宣传展示',img:require('@/assets/images/model2.png')},
        {name:'列表型',title:'应用名称',type:"list",desc:'适用于多条并列内容',img:require('@/assets/images/model3.png')},
        {name:'web-view形',title:'应用名称',type:"webview",desc:'承载网页的容器，适用于展示复杂内容',img:require('@/assets/images/model4.png')},
      ],
      apilist_data:[], // 系统模板数据
      apilist_arr:{}, // 选中系统模板数据
      modelListb:[//系统模板类型

      ],
      currentIndex:-1,//当前模板index
      showIcon:[],
      pushList:[//推送范围列表
        {
          obj_name:'全员推送',
          send_user:[],
          send_department:[],
          is_all:1,
          modulars:null
        },
      ],
      iconType:0,
      sourceId:'',//数据源id
      iconModel:'',
      modularIndex:-1,//当前showIcon索引
      appIds:[],//已选择应用id列表
      openAppType:1,
      userIds:[],//推送成员列表
      depIds:[],//推送部门列表
      send_obj:[],//
      pickids:[],//所选部门成员id
      firstShowIcon:[],
      newShowIcon:[],
      addGroup:false,
      dialogVisible:false,
      editIndex:-1,
      keyDataShowIcon:[
        {name:'示例一',value:8},
        {name:'示例二',value:8},
        {name:'示例三',value:8},
        {name:'示例四',value:8},
      ],
      listShowIcon:[
        {name:'示例一'},
        {name:'示例二'},
        {name:'示例三'},
      ],
      modelList:[],
      shake: true
    }
  },
  created() {
    this.getSourceLists()
    this.getTagList() // 获取数据源标签
    // this.modelList = this.modelLista // 初始赋值
    this.id=this.$route.query.id;
    this.editType=this.$route.query.type ? this.$route.query.type :0;
    if(this.id){
      this.getModelDet(this.id)
    }else{
      this.ruleForm.name='模板'+Math.floor(Math.random()*1000)+Math.floor(Math.random()*1000)
    }
  },
  computed:{
    pushIcon(){
      if(this.editType==2){
        return function (type,icon){
          return icon.image;
        }
      }else{
        return function (type,icon){
          if(type=='keydata'){
            return require('@/assets/images/model1.png')
          }else if(type=='image'){
            return require('@/assets/images/model2.png')
          }else if(type=='list'){
            return require('@/assets/images/model3.png')
          }else{
            return require('@/assets/images/model4.png')
          }

        }
      }

    }
  },
  methods:{
    // 拖拽
    //evt里面有两个值，一个evt.added 和evt.removed  可以分别知道移动元素的ID和删除元素的ID
    change(evt) {
        // console.log(evt , 'change...')
    },
    //start ,end ,add,update, sort, remove 得到的都差不多
    start(evt) {
        this.drag = true
        // console.log(evt , 'start...')
    },
    end(evt) {
      // console.log(evt , 'end....')

      this.showModelDet(this.showIcon[this.modularIndex],this.modularIndex) // 重置右侧内容

      // 循环替换拖动数据
      let data = []
      this.showIcon.map((res_a)=>{
        this.pushList[this.selectPushIndex].modulars.map((res_b)=>{
          if(res_a.id==res_b.id){ return data.push(res_b) }
        })
      })

      this.pushList[this.selectPushIndex].modulars = data


        this.drag = true
        evt.item //可以知道拖动的本身
        evt.to    // 可以知道拖动的目标列表
        evt.from  // 可以知道之前的列表
        evt.oldIndex  // 可以知道拖动前的位置
        evt.newIndex  // 可以知道拖动后的位置
    },
    move(evt, originalEvent) { // 拖动后数据排序
        // console.log(evt , 'move')
        // console.log(originalEvent) //鼠标位置
    },

    // 获取系统模板
    getSourceLists(){
      if(!this.shake){ return false }
      this.shake = false
      let temporaryTitle = ''
      sourceLists({
        is_id_key: 2,	// 是否使用id为键 取值范围（1是,2否）
        type:	'',// 数据源模块类型 取值范围（ keydata、 image、 list、 webview ）
        api_type: 4,	// 数据源类型 1 API接口 2名冠API接口 3 数据库 4系统自带接口。如果不传值，则默认查询除=4的其他类型
        keyword: this.keyword, // 关键词搜索
        api_tag_id:	this.api_tag_id, // 标签id
      }).then(data=>{
        if(data.code===200){
          this.apilist_data = data.data
          if(data.data.length>10){ this.status = true }
          data.data.map((resa)=>{
            this.modelLista.map((resb)=>{
              if(resa.type == resb.type){
                // if(temporaryTitle == resb.name){
                //      resb.name = ''
                // }
                let arr = Object.assign({},resb)
                    arr['access_token'] = resa.access_token
                    arr['wv_type'] = resa.wv_type
                    arr['api_type'] = resa.api_type
                    arr['name'] = resa.brand_name
                    arr['desc'] = resa.system_name
                    arr['function_name'] = resa.function_name
                    arr['api_version'] = resa.api_version
                switch(resa.access_token){
                  case 'mgtxapi_news':
                    arr['title'] = '企业新闻'
                  break
                  case 'mgtxapi_wxsp':
                    arr['title'] = '待办事项'
                  break
                }
                this.modelListb.push(arr)
                // temporaryTitle = resb.name
              }
            })
          })
          this.modelList = []
          this.modelList = data.data.length>0?this.modelListb:[] // 初始赋值
          this.shake = true
          this.loadstate = true
        }else{
          this.$message({
            type:'warning',
            message:'未获取到数据'
          })
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    // 获取数据源标签
    getTagList(){
      getApiTagList().then(data=>{
        if(data.code===200){
          this.api_tag_list = data.data
        }else{
          this.$message({
            type:'warning',
            message:'未获取到数据'
          })
        }
      }).catch(err=>{
        console.log(err)
      })
    },
   
    getModelDet(id){
      this.loading=true;
      modelDetail({id}).then(res=>{
          this.appIds=[];
        if(res.code===200){
          let info=res.data;
          this.ruleForm.name=this.editType==3 ? info.temp_name+'--复制版' : info.temp_name;
          this.ruleForm.remark=this.editType==3 ? info.remark+'--复制版' : info.remark;
          this.ruleForm.cover=info.cover;
          this.pushList=info.send_obj;
          this.showIcon=this.pushList[0].modulars;
          this.firstShowIcon=this.pushList[0].modulars;
          this.showIcon.map(item=>{
            if(item.app_id){
              this.appIds.push(item.app_id)
            }
          })
          if(this.editType==2){
            this.showIcon.map((icon,index)=>{
              info.image.map(item=>{
                this.showIcon[index].image=info.image[index].show_image
              })
            })
          }
          this.newShowIcon= this.pushList[1] ? this.pushList[1].modulars : [];
          this.showModelDet(this.showIcon[0],0)
        }else{
          this.$message({
            type:'warning',
            message:'未获取到数据'
          })
        }
        this.loading=false;
      }).catch(err=>{
        console.log(err)
        this.loading=false;
      })
    },
    getPushDetail(item,index){
      this.modularIndex=0;
      this.showIcon=JSON.parse(JSON.stringify(item));
      this.selectPushIndex=index;
      this.showModelDet(item[0],0)
    },
    showModelDet(icon,index){
      this.modularIndex=index;
      const type=icon.modular_type;
      // 判断是否系统模板
      if(icon.api_type==4){
        this.apilist_data.map((res)=>{ //回显
          if(res.access_token==icon.access_token){
             if(res.access_token==icon.access_token && res.type == icon.modular_type){
                if(icon.data_id != undefined){
                  res.id= icon.data_id
                }
                if(icon.wv_type == '' || icon.wv_type == undefined){
                    this.apilist_arr = Object.assign({},res)
                }else{
                  if(icon.wv_type == res.wv_type){
                    this.apilist_arr = Object.assign({},res)
          
                  }
                }
                this.apilist_arr['type_all'] = 0
            }
          }
        })
      }else if(icon.access_token){ // 新增
        this.apilist_arr = icon
        this.apilist_arr['type_all'] = 1
        // this.apilist_data.map((res)=>{
        //   if(res.access_token==icon.access_token && res.type == icon.modular_type){
        //       if(icon.data_id != undefined){
        //           res.id= icon.data_id
        //         }
        //      if(icon.wv_type == '' || icon.wv_type == undefined){
        //         this.apilist_arr = Object.assign({},res)
        //         this.apilist_arr['type_all'] = 1
        //      }else{
        //         if(icon.wv_type == res.wv_type){
        //             this.apilist_arr = Object.assign({},res)
        //             this.apilist_arr['type_all'] = 1
        //         }
        //      }
        //   }
        // })
       
      }else{
        if(icon.data_id != '' && icon.data_id != undefined){
           this.apilist_arr = {
             data_id: icon.data_id
           }
        }else{
          this.apilist_arr = {}
        }
         this.apilist_arr['type_all'] = 1
      }
      this.$refs.setDetail.setSelectType(type,icon,this.appIds,this.selectPushIndex);
    },
    addPushItem(){
      this.groupInput='';
      // this.dialogPush=true;
      if(this.showIcon.length===0){
        this.$message({
          type:'warning',
          message:'请先设置全员推送列表'
        })
        return false
      }else{
        if(this.showIcon[0].content.length===0){
          this.$message({
            type:'warning',
            message:'请先保存设置的模块'
          })
          return false
        }else{
          this.dialogPush=true;
        }
      }
    },
    goToHome(){
      this.$router.push('/home/index')
    },
    beforeChange(item,command,index){
      return {
        'item':item,
        'command':command,
        'index':index,
      }
    },
    handleChange(command){
      switch (command.command) {
        case "a"://修改推送名
          this.updateGroupName(command.item,command.index)
          break;
        case "b"://修改推送对象
            this.pickUser(command.item,command.index)
          break;
        case "c"://删除
            this.deleteGroup(command.item,command.index)
          break;
      }
    },
    updateGroupName(item,index){
      this.selectPushIndex=index;
      this.editPush=true;
      this.editGroupInput=item.obj_name;
      this.editIndex=index;
    },
    editGroupName(){
      this.pushList[this.editIndex].obj_name=this.editGroupInput.trim()
      this.editPush=false;
    },
    deleteGroup(item,index){
      this.pushList.splice(index,1);
      this.selectPushIndex=0;
      this.getPushDetail(this.firstShowIcon,this.selectPushIndex)
    },
    selectType(item){//选择模式
      if(this.showIcon.length>0){
        if(this.showIcon[this.showIcon.length-1].content.length===0){
          this.$message({
            type:'warning',
            message:'请先保存设置的模块'
          })
          return false;
        }
      }
      if(this.selectPushIndex>0){
        this.$message({
          type:'warning',
          message:'请在全员推送中设置模块'
        })
        return false;
      }
      this.showIcon.push({
        modular_name: item.access_token?item.title:'应用名称',
        modular_type:item.type,
        access_token: item.access_token?item.access_token:undefined,
        app_id: item.api_id,
        api_path:item.api_path,
        set_type: item.type=="keydata" ? 3 :1,
        content:[],
        api_type:item.api_type,
        wv_type:item.wv_type?item.wv_type:undefined
      })
      // 判断是否系统模板
      if(item.access_token){
        this.apilist_data.map((res)=>{
          if(res.access_token==item.access_token){
            this.apilist_arr = res
            this.apilist_arr['type_all'] = 0
          }
        })
      }else{
        this.apilist_arr = {}
      }
      // yrk---点击左侧菜单不需要再点中间才能渲染右侧的值
      this.showModelDet(this.showIcon[this.showIcon.length-1],this.showIcon.length-1)
      this.firstShowIcon=this.showIcon
      // this.firstShowIcon.push({
      //   modular_name:'',
      //   modular_type:item.type,
      //   app_id: null,
      //   set_type: item.type=="keydata" ? 3 :1,
      //   content:[],
      // })
      this.modularIndex=this.showIcon.length-1
      this.$refs.setDetail.setSelectType(item.type,null,this.appIds,this.selectPushIndex);
    },
    beforeCom(icon,command,index){//传参
      return {
        'icon':icon,
        'command':command,
        'index':index,
      }
    },
    handleCommand(command){//更多操作
      switch (command.command) {
        case "a"://删除
          this.delShowIcon(command.index)
          break;
        case "b":
          break;
        case "c":
          break;
      }
    },
    delShowIcon(res){//删除模板
      let index = res>0?res-1:res
      this.modularIndex = index
      this.appIds=[];
      this.showIcon.splice(res,1);
      // this.firstShowIcon.length>0 ? this.firstShowIcon.splice(res,1) : ''
      this.newShowIcon.length>0 ? this.newShowIcon.splice(res,1) : ''
      this.showIcon.map(item=>{
        if(item.app_id){
          this.appIds.push(item.app_id)
        }
      })
      this.pushList[0].modulars=this.firstShowIcon;
      this.$forceUpdate();
      this.showIcon.length>0 ?
      this.$refs.setDetail.setSelectType(this.showIcon[index].modular_type,this.showIcon[index],this.appIds,this.selectPushIndex) : this.$refs.setDetail.setSelectType('',null,this.appIds,this.selectPushIndex)

    },
    // 选择成员弹窗
    pickStuff() {//新建
      if(this.groupInput.trim()){
        this.dialogPush=false;
        this.showPickDir = true;
        this.addGroup=true;
      }else{
        this.$message({
          type:'warning',
          message:'请输入推送对象群名'
        })
        return false;
      }
    },
    pickUser(item,index){//重新编辑人员
      this.pickids=[...item.send_department,...item.send_user]
      this.showPickDir = true;
      this.addGroup=false;
      this.selectPushIndex=index;
      this.$refs.pickShare.chooseType(this.pickids,item.send_department,item.send_user);
    },
    // 选择成员
    addUsers(data) {
      this.userIds = [];
      this.depIds = [];

      this.userIds=data.filter(item => item.is_user).map(item => item.userid)
      this.depIds=data.filter(item => !item.is_user).map(item => item.dep_id)
      if(this.addGroup){//新增
        this.pushList.push({
          obj_name:this.groupInput,
          send_user:this.userIds,
          send_department:this.depIds,
          is_all:2,
          modulars:JSON.parse(JSON.stringify(this.firstShowIcon)),
        })
        this.selectPushIndex=this.pushList.length-1;
        this.getPushDetail(this.pushList[this.selectPushIndex].modulars,this.selectPushIndex)
        this.newShowIcon= this.pushList[1] ? this.pushList[1].modulars : [];
      }else{
        this.pushList[this.selectPushIndex].send_user=this.userIds
        this.pushList[this.selectPushIndex].send_department=this.depIds
      }

    },
    backToModel(){//取消
      this.$router.push('/home/workbench')
    },
    saveModel(){//保存暂不开启
      this.startModel(2)
    },
    submitModel(){//启用
      if(this.showIcon.length===0){
        this.$message({
          type:'warning',
          message:'请先设置模板'
        })
        return false
      }
      if(!this.ruleForm.name){
        this.$message({
          type:'warning',
          message:'请输入模板基本信息'
        })
        return false
      }
      this.dialogVisible=true;
    },
    getUseModel(){
      this.modelLoading=true;
      this.startModel(1)
      this.dialogVisible=false;
    },
    startModel(type){
      if(this.showIcon.length===0){
        this.$message({
          type:'warning',
          message:'请先设置模板'
        })
        return false
      }
      if(!this.ruleForm.name){
        this.$message({
          type:'warning',
          message:'请输入模板基本信息'
        })
        return false
      }
      let used_app_id = this.pushList[0].modulars.map((res)=>{ return res.app_id })
      this.pushList.forEach(item=>{
          item.modulars.forEach(item_info=>{
              item_info.content.forEach(item_info1=>{
                if(item_info1.jump_url != undefined && item_info1.webview_template  != undefined){
                   if(item_info1.jump_url.indexOf('&id') != -1){
                      item_info1.jump_url = item_info1.jump_url.substring(0,item_info1.jump_url.indexOf('&id'))
                    }
                     item_info1.jump_url = (item_info1.jump_url + `&id=${item_info1.webview_template}`)
                }
                if(item_info1.url != undefined && item_info1.webview_template  != undefined){
                   if(item_info1.url.indexOf('&id') != -1){
                      item_info1.url = item_info1.url.substring(0,item_info1.url.indexOf('&id'))
                    }
                    item_info1.url = item_info1.url + `&id=${item_info1.webview_template}`
                }
              })
          })
      })
      let data={
        temp_name:this.ruleForm.name,
        remark:this.ruleForm.remark,
        temp_status:type,
        used_app_id:used_app_id,
        send_obj:this.pushList
      };

      const that=this;
      let requestUrl=null;
      if(this.id && this.editType==1){
        data.id=this.id;
        requestUrl= editModel(data)
      }else{
        requestUrl=newModel(data)
      }
      requestUrl.then(res=>{
        if(res.code===200){
          that.loading=false
          that.$message({
            type:'success',
            message:res.message,
            duration:2000,
            onClose(){
              that.$router.replace('/home/workbench')
            }
          })
        }
        this.modelLoading=false;
      }).catch(err=>{
        this.modelLoading=false;
        that.loading=false
        console.log(err)
      })
    },
    setDetail(data){
      if(this.selectPushIndex===0){
        this.appIds=[];
        this.showIcon[this.modularIndex]=data;
        this.firstShowIcon[this.modularIndex]=data;
        this.pushList[0].modulars=this.firstShowIcon;
        this.showIcon.map(item=>{
          if(item.app_id){
            this.appIds.push(item.app_id)
          }
        })
        if(this.firstShowIcon.length>this.newShowIcon.length){
          this.newShowIcon.push(data)
          this.pushList.length>1 ? this.pushList[1].modulars=this.newShowIcon : ''
        }
        }else{
          this.newShowIcon[this.modularIndex]=data;
          this.pushList[this.selectPushIndex].modulars=this.newShowIcon
        }
        this.$forceUpdate()
    },

    // tab切换
    ontabBan(type){
      this.tabType = type
      this.modelList = type==0?this.modelLista:this.modelListb // 赋值
      let duplicateRemoval = ''
      this.modelList.forEach(item=>{
        if(duplicateRemoval == item.name){
            item.name = ''
            item.desc = ''
        }  
        duplicateRemoval = item.name
      })
    }
  },
}
</script>

<style lang="less" scoped>
.other-head{
  width: 100%;
  height: 64px;
  background: #fff;
  position: fixed;
  top:0;
  z-index: 99;
  border-bottom:1px solid #DBDBDB;
  .home-head{
    height: 100%;
    width: 95%;
    //width:1278px;
    margin:0 auto;
    .head-title{
      margin-right: 110px;
      .head-logo{
        height: 31px;
        cursor: pointer;
      }
      p{
        font-size: 18px;
        margin-left: 11px;
      }
    }
    .model-btns{
      text-align: center;

      div{
        line-height: 28px;
        height: 28px;
        border-radius: 2px;
        margin-left: 16px;
        cursor: pointer;
      }
      .cancel{
        width: 72px;
        border: 1px solid #CCCCCC;
      }
      .save{
        width: 96px;
        border: 1px solid #CCCCCC;
      }
      .use{
        width: 72px;
        background: #0082EF;
        color:#fff;
      }
    }
  }
}
.main{
  padding-top: 64px;
  background: #eee;
  .middle-con{
    min-height: calc(100vh - 84px);
    flex:1;
    min-width: 650px;
    position: relative;

    .push-menu{
      position: absolute;
      left: calc(50% - 322px);
      top:calc(50% - 305px);
      .menu-item{
        width: 150px;
        height: 36px;
        line-height: 36px;
        background: #FFFFFF;
        //padding-left:12px;
        margin-bottom: 8px;
        color:#4D4D4D;
        cursor: pointer;
        position: relative;

        padding: 8px 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        .left-blue{
          height: 20px;
          line-height: 20px;
          span{
            padding-left: 8px;
          }
        }
        .obj-name{
          padding-left: 8px;
          width: 120px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .push-active{
          color:#0082ef;
          border-left: 4px solid #0082ef;
        }
        .el-dropdown.group-icon{
          position: absolute;
          left: 117px;
          top:9px;
          color:#999999;
          display: none;
        }
        &:hover{
          .group-icon{
            display: inline-block;
          }
          color:#0082EF;
        }
      }
      .menu-items{
        width: 150px;
        height: 36px;
        line-height: 36px;
        background: #FFFFFF;
        margin-bottom: 8px;
        color:#4D4D4D;
        cursor: pointer;
        position: relative;
      }
      .menu-add{
        text-align: center;
      }
    }
    .split{
      width: 320px;
      .bg-top,.bg-bot{
        width: 100%;
      }
      .bg-show{
        margin-top: -4px;
        width: 100%;
        height:490px;
        background:#F5F5F5 ;
        box-shadow: 0 3px 2px rgba(0, 0, 0, 0.16);
        .no-data{
          text-align: center;
            padding-top:220px;
          .no-data-icon{
            width: 32px;

            height: 32px;
          }
        }
        .show-model-list{
          height: 490px;
          width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          .list{
            padding: 0 0 14px;
          }
        }
        .show-model-list::-webkit-scrollbar{
          width: 0 !important;
        }
        .show-item{
          cursor: all-scroll;
          width: 280px;
          position: relative;
          margin:14px 11px 0;
          .show-model-icon{
            width: 298px;
            background: #fff;
            border-radius: 4px 4px 0 0;
            .top-app-name{
              border-bottom: 1px solid #eee;
              height: 47px;
              .show-icon-app-name{
                width:200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .four-icon{
                padding: 0 10px 0 20px;
              }
            }
            .bot-main-cont{
              height: 82px;
              .keydata-name{
                font-size: 13px;
                color: #909499;
                padding-bottom: 5px;
              }
              .keydata-value{
                font-size: 21px;
                color: #FCCF53;
                text-align: center;
              }
            }
            .bot-main-cont2{
              .list-name{
                border-bottom: 1px solid #eee;
                padding: 10px 0 10px 15px;
              }
            }
            .bot-main-cont3{
              height: 130px;
              padding: 5px;
              overflow:hidden;
            }
          }
          .show-more{
            background: #fff;
            width: 20px;
            height: 20px;
            font-size: 16px;
            font-weight: bold;
            color:#0082EF;
            position: absolute;
            right:-10px;
            cursor: pointer;
            top:13px;
          }
        }
        .modular-active{
          box-shadow: 0 4px 18px rgba(0, 0, 0, 0.36);
        }
      }
    }

  }
  .hide-left{
    min-width:20px;
    width: auto;
    background: #FCFCFC;
    height: calc(100vh - 64px);
    //display: flex;
    //align-items: center;
    overflow: hidden;
    cursor:pointer;
    .el-icon-s-unfold{padding: 14px;}
  }
  .left-set{
    background: #FCFCFC;
    min-width: 320px;
    height: calc(100vh - 64px);
    overflow-y: auto;
    position: relative;
    
    .tabBanAll{
      height: 50px;
      background: #F5F5F5;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      .el-icon-s-fold{padding: 10px 14px;}
    }
  }
  .MaskFather{
      position: relative;
      .mask-out{
        z-index:2020;
        position:absolute;
        top:0;
        left: 0;
        width:100%;
        height:100%;
        cursor: not-allowed;
    }

    // 搜索
    .search{margin: 16px 0 0;
      display: flex;
      .el-input{flex: 1;}
      /deep/input,/deep/.el-input__icon{height: 32px;line-height: 32px;background: #F0F1F2;}
      /deep/.el-input__icon{height: 30px;line-height: 30px;}
    }
    /deep/span{display: flex;align-items: center;height: 32px;}
    /deep/.el-popover__reference-wrapper{position: relative;width: 55px;}
    .screen{
      position: absolute;
      right: -16px;
      height: 32px;
      padding: 0 15px 0 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 14px;
      img{margin: 0 4px 0 0;}
      cursor: pointer;
    }
  }
 
  .left-set::-webkit-scrollbar{
    width: 0;
  }
  .right-set::-webkit-scrollbar{
    width: 0;
  }
  .left-set{position: relative;
    border-right: 1px solid #DBDBDB;
    padding: 50px 16px 60px;
    .base-title{
      font-weight: bold;
      font-size: 12px;
      padding-top: 12px;
      color: #999999;
    }
    .push{
      padding-bottom: 25px;
      border-bottom: 1px solid #DBDBDB;;
    }
    /deep/.left-form{
      padding-top: 16px;
      /deep/.el-form-item__label{
        padding:0 0 4px!important;
      }
      /deep/.el-input__inner,/deep/.el-textarea__inner{
        resize: none;
      }
    }

    // tab切换
    .tabBan{display: flex;align-items: center;
      div+div{margin: 0 0 0 5px;}
      div{font-size: 13px;font-weight: bold;color:#666;padding: 3px 15px 4px;cursor:pointer;}
      .active{background: #7D94B3;color: #fff;border-radius: 13px;}
    }

    .model-item{
      .model-title{
        padding: 10px 0 10px;
        .title-desc{
          color: #B7B7B7;
          margin-left: 10px;
          font-size:12px;
        }

      }
      .select-img{
        cursor: pointer;
      }

      .Default{
        cursor:pointer;
        position: relative;
        .show-model-icon{
          width: 287px;
          border: 1px solid #eee;
          background: #fff;
          border-radius: 4px;
          .top-app-name{
            border-bottom: 1px solid #eee;
            height: 45px;
            .show-icon-app-name{
              color: #303133;
              font-size:12px;
              font-weight: 400;
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            span{font-size: 17px;color: #909499;padding: 0 5px;}
            .four-icon{
              padding: 0 10px 0 20px;
            }
          }
          .bot-main-cont{
            height: 82px;
            .keydata-name{
              font-size:12px;
              font-weight: 400;
              color: #909499;
              padding-bottom: 5px;
            }
            .keydata-value{
              font-size:18px;
              font-weight: 500;
              color: #FCCF53;
              text-align: center;
            }
          }
          .bot-main-cont2{
            .list-name+.list-name{border-top: 1px solid #eee;}
            .list-name{
              padding: 10px 0 10px 15px;
              color: #303133;
            }
          }
          .bot-main-cont3{
            height: 130px;
            padding: 5px;
            overflow: hidden;
          }
        }
      }

    }
  }
  .right-set{
    border-left: 1px solid #DBDBDB;
    //padding-bottom: 54px;
    background: #FCFCFC;
    min-width: 320px;
    max-width: 320px;
    height: calc(100vh - 64px);
    overflow-y: auto;
    position: relative;
    .mask{
      z-index:2020;
      position: absolute;
      top:0;
      left: 0;
      width:100%;
      height:100%;
      cursor: not-allowed;
    }
  }
}

/deep/.push-dialog{
  .el-dialog__header{
    padding:0;
  }
  .notice{
    .notice-title{
      color:#999;
    }
    .notice-con{
      font-size: 13px;
      color:#333;
      padding: 10px 0 14px;
    }
    .notice-add{
      margin-top: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #FFFFFF;
      cursor: pointer;
      border: 1px dashed #DBDBDB;
    }
  }
}

/deep/.el-dialog__body::-webkit-scrollbar {
  width: 0;
}
/deep/.el-dialog__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(0,0,0,0.2);
}
/deep/.el-dialog__body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}


/deep/.pick-user{
  .user-gorp {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .user-gorp span {
    margin: 0 5px;

  }

  .tip—table {
    padding: 0 34px;
    background: #ffefd6;
    line-height: 36px;
    border: 1px solid #f6ddc7;
    box-sizing: border-box
  }

  .layui-form-checked[lay-skin=primary] i {
    border-color: #5ba1df;
    background-color: #5ba1df
  }

  .layui-btn-primary:hover {
    background-color: #5ba1df;
  }

  .layui-table-page {
    background-color: #fff;
  }

  .el-dialog {
    min-width: 400px;
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;

  }

  .wrapper .left {
    padding-right: 25px;
    border-right: 1px solid #ccc;
  }

  .wrapper .right {
    margin-left: 25px;
  }

  .left, .right {
    flex: 1;
  }

  .left .el-scrollbar, .right .el-scrollbar {
    height: 40vh;
    //height: 120px;
    padding-top: 10px;
  }

  .left .el-scrollbar__wrap, .right .el-scrollbar__wrap {
    overflow: scroll;
    overflow-x: hidden;

  }


  .tree-item {
    display: flex;
    align-items: center;
  }

  .tree-title {
    margin-left: 5px;
  }

  .user-item {
    display: flex;
    align-items: center;
    padding: 1px 0;
    padding-right: 8px;
  }

  .user-title {
    margin: 0 10px;
    flex: 7;
  }

  .el-icon-folder {
    font-size: 16px;
  }

  .right-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .hover-header {
    padding: 10px 0;
    font-size: 14px;
  }

  .hover-list .el-scrollbar {
    height: 250px;
  }

  .hover-list .el-scrollbar__wrap {
    overflow: scroll;
    overflow-x: hidden;

  }

  .select-popper {
    display: none;
  }

  .el-select__tags .el-tag__close {
    display: none;
  }

  .state-list {
    display: flex;
    align-items: center;
  }

  .state-item {
    padding: 10px 0;
    margin-right: 20px;
    cursor: pointer;
  }

  .select-item {
    display: flex;
    align-items: center;
  }

  .select-key {
    margin-right: 20px;
    width: 100px;
  }
}
</style>

<style>
  .screenLayer .el-popover__title{
    font-size: 12px;
    font-weight: 400;
    color: #999;
  }
  .screenLayer .screenList div{
    width: 140px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #333;
    padding: 0 10px;
    cursor: pointer;
    justify-content: space-between;
  }
  .screenLayer .screenList div:hover{background: #F5F8FA;}
  .screenLayer .screenList .active{background: rgba(0, 130, 239, 0.1);color: #0082EF;}
</style>
