<template>
  <div class="new-model"  v-loading="loading">
    <div class="flex-bet right-title">
      <div>模块设置</div>
      <el-button v-show="iconType!==0" icon="el-icon-plus" @click="addModel" size="mini">新增</el-button>
    </div>
    <el-form ref="topform" label-position='left'
             :model="topform" class="top-form" v-show="iconType!==0">
      <!--      <el-form-item style="">-->
      <!--        <el-input v-model="topform.modelName" placeholder="请输入模块名称"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item>
        <div class="flex-start">
          <div class="app-label-name">应用名称：</div>
          <div class="cart flex-bet">
            <span class="app-name-input">{{ topform.appName }}</span>
            <el-button @click="openAppList" size="mini">{{ topform.appName ? '切换' : '选择' }}</el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <el-form ref="typeform" label-position='top' :model="typeform" class="mid-form" v-show="iconType!==0">
      <el-form-item label="设置类型">
        <el-radio-group v-model="typeform.set_type">
          <el-radio v-show="iconType!==1" :label="1" :disabled="type_all == 0">自定义</el-radio>
          <el-radio v-show="iconType===1" :label="3" :disabled="type_all == 0">分开设置项</el-radio>
          <el-radio v-show="iconType===1" :label="2">设置统一数据源</el-radio>
          <el-radio v-show="iconType!==1" :label="2">选择数据来源</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <!--  keydata型-->
    <div v-show="typeform.set_type===3 && iconType===1">
      <el-form ref="form" label-position='left' :model="left" class="right-form-keyData"
               v-for="(left,index) in keyDataForm" :key="index">
        <el-form-item class="float-r " :label="'示例'+(index+1)">
          <el-switch class="table-scope" active-text="显示"
                     inactive-text="隐藏"
                     active-value="1"
                     inactive-value="0"
                     v-model="left.is_show"></el-switch>
        </el-form-item>
        <el-form-item label="选择数据源" v-if="left.is_show" v-show="left.set_type1===2 || left.set_type2===2">
          <div class="flex-bet select-data">
            <span class="app-name-input">{{ left.data_source }}</span>
            <el-button type="primary" @click="chooseData(index)" size="mini">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item class="radio-top" :label="'示例'+(index+1)+'名称：'" v-show="left.is_show">
          <el-radio-group v-model="left.set_type1">
            <el-radio :label="1">自定义</el-radio>
            <el-radio :label="2">数据源</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.set_type1===1 ">
          <el-input class="no-border" v-model="left.key" :placeholder="'  请输入示例'+(index+1)+'名称'"></el-input>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.set_type1===2">
          <el-select class="full-select" v-model="left.key_field" :placeholder="'请选择示例'+(index+1)+'名称字段'">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="radio-top" v-show="left.is_show" :label="'示例'+(index+1)+'值：'">
          <el-radio-group v-model="left.set_type2">
            <el-radio :label="1">自定义</el-radio>
            <el-radio :label="2">数据源</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.set_type2===1">
          <el-input class="no-border" v-model="left.data" type="number" :placeholder="'  请输入示例'+(index+1)+'统计值'"></el-input>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.set_type2===2">
          <el-select class="full-select" v-model="left.data_field" :placeholder="'请选择示例'+(index+1)+'值字段'">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="left.is_show">
          <el-radio-group class="radio-midden" v-model=" left.is_jump">
            <el-radio :label="0">不用跳转</el-radio>
            <el-radio :label="1">点击跳转（静态链接）</el-radio>
            <el-radio :label="2" v-show="left.set_type1==2 || left.set_type2==2">点击跳转（动态链接）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.is_jump===1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="left.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.is_jump===1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="left.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.is_jump===2">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-select class="full-select" v-model="left.jump_field" placeholder="请选择跳转链接字段">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="left.is_show && left.is_jump===2">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-select class="full-select" v-model="left.pagepath_field" placeholder="请选择小程序跳转链接字段">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

    </div>
    <!-- keydata   选择数据源-->
    <div v-show="typeform.set_type===2 && iconType===1">
      <el-form ref="form" label-position='left'
               :model="keyModelForm" class="right-form-keyData">
        <el-form-item label="选择数据源">
          <div class="flex-bet select-data">
            <span class="app-name-input">{{ keyModelForm.data_source }}</span>
            <el-button type="primary" @click="chooseData()" v-show="this.apilist.id?false:true" size="mini" v-if=" type_all == 1">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item label="示例名称:">
          <el-select class="full-select" v-model="keyModelForm.key_field" placeholder="示例对应的示例名称字段">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统计数据">
          <el-select class="full-select" v-model="keyModelForm.data_field" placeholder="示例对应的统计数据字段">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="radio-midden">
          <el-radio-group v-model="keyModelForm.is_jump">
            <el-radio :label="0" :disabled="type_all == 0" >不用跳转</el-radio>
            <el-radio :label="1" :disabled="type_all == 0">点击跳转（静态链接）</el-radio>
            <el-radio :label="2" :disabled="type_all == 0">点击跳转（动态链接）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="keyModelForm.is_jump===1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="keyModelForm.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="keyModelForm.is_jump===1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="keyModelForm.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
        <el-form-item v-show="keyModelForm.is_jump===2">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-select class="full-select" v-model="keyModelForm.jump_field" placeholder="请选择跳转链接字段">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="keyModelForm.is_jump===2">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-select class="full-select" v-model="keyModelForm.pagepath_field" placeholder="请选择小程序跳转链接字段">
            <el-option
                v-for="item in jumpOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="read-num" label="读取条数">
          <el-select class="full-select" v-model="keyModelForm.limit">
            <el-option
                v-for="item in keyNumOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0"
                >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>


    <!--    图片型-->
    <div v-show="typeform.set_type===1 && iconType===2">
      <el-form ref="form" label-position='left'
               :model="imgForm" class="right-form">
        <el-form-item>
          <el-upload
              class="avatar-uploader"
              action=""
              :http-request="httpRequest"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload">
            <img v-if="imgForm.url" :src="imgForm.url" class="avatar">
            <div class="flex-cent avatar-uploader-icon" v-else>
              <i class="el-icon-plus"></i>
              <div class="fz12">
                <div>建议图片尺寸：1080X362</div>
                <div>大小限制：1M</div>
              </div>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否跳转">
          <div class="flex-bet mt8">
            <div></div>
            <el-switch active-value="1" inactive-value="0" v-model="imgForm.is_jump"></el-switch>
          </div>
        </el-form-item>
        <el-form-item v-show="imgForm.is_jump==1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="imgForm.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="imgForm.is_jump==1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="imgForm.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图片型   选择数据源-->
    <div v-show="typeform.set_type===2 && iconType===2">
      <el-form ref="form" label-position='left'
               :model="imgForm" class="right-form-last">
        <el-form-item label="选择数据源">
          <div class="flex-bet select-data">
            <span class="app-name-input">{{ imgForm.data_source }}</span>
            <el-button type="primary" @click="chooseData()" size="mini" v-if="type_all == 1">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item label="选择图片">
          <el-select v-model="imgForm.field" placeholder="请选择图片链接字段">
            <el-option
                v-for="item in imgOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否跳转">
          <el-radio-group v-model="imgForm.is_jump">
            <el-radio :label="0" :disabled="type_all == 0">不用跳转</el-radio>
            <el-radio :label="1" :disabled="type_all == 0">点击跳转（静态链接）</el-radio>
            <el-radio :label="2" :disabled="type_all == 0">点击跳转（动态链接）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="imgForm.is_jump===1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="imgForm.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="imgForm.is_jump===1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="imgForm.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
        <el-form-item v-show="imgForm.is_jump===2">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-select v-model="imgForm.jump_field" placeholder="请选择跳转链接字段">
            <el-option
                v-for="item in imgOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="imgForm.is_jump===2">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-select v-model="imgForm.pagepath_field" placeholder="请选择小程序跳转链接字段">
            <el-option
                v-for="item in imgOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!--    list型-->
    <div v-show="typeform.set_type===1 && iconType===3">
      <el-form ref="form" label-position='left'
               :model="left" class="right-form-list"
               v-for="(left,index) in listForm" :key="index">
        <div class="del-model fz12" @click="delLeftForm(index)">删除</div>
        <el-form-item :label="'标题'+(index+1)">
          <el-input v-model="left.title" placeholder="  输入标题"></el-input>
        </el-form-item>
        <el-form-item label="是否跳转">
          <div class="flex-bet mt8">
            <div></div>
            <el-switch active-value="1" inactive-value="0" v-model="left.is_jump"></el-switch>
          </div>
        </el-form-item>
        <el-form-item v-show="left.is_jump==1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="left.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="left.is_jump==1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="left.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- list型   选择数据源-->
    <div v-show="typeform.set_type===2 && iconType===3">
      <el-form ref="form" label-position='left' :model="listModelForm" class="right-form-last">
        <el-form-item label="选择数据源">
          <div class="flex-bet select-data">
            <span class="app-name-input">{{ listModelForm.data_source }}</span>
            <el-button type="primary"  @click="chooseData()" v-show="this.apilist.id?false:true" v-if="this.type_all ==1" size="mini">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item label="标题">
          <el-select v-model="listModelForm.field" placeholder="请选择数据源字段">
            <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否跳转">
          <el-radio-group v-model="listModelForm.is_jump">
            <el-radio :label="0" :disabled="type_all == 0">不用跳转</el-radio>
            <el-radio :label="1" :disabled="type_all == 0">点击跳转（静态链接）</el-radio>
            <el-radio :label="2" :disabled="type_all == 0">点击跳转（动态链接）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="listModelForm.is_jump===1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="listModelForm.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="listModelForm.is_jump===1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="listModelForm.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
        <el-form-item v-show="listModelForm.is_jump===2">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-select v-model="listModelForm.jump_field" placeholder="请选择跳转链接字段">
            <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="listModelForm.is_jump===2">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-select v-model="listModelForm.pagepath_field" placeholder="请选择小程序跳转链接字段">
            <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all == 0">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="read-num" label="读取条数">
          <el-select v-model="listModelForm.limit">
            <el-option
                v-for="item in listNumOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    
    <!--    webview型-->
    <div v-show="typeform.set_type === 1 && iconType===4">
      <el-form ref="form" label-position='left' :model="webViewForm" class="right-form-webview">
        <el-form-item label="选择模板类型">
          <div class="flex-bet select-data">
            <span class="app-name-input">{{comPonent == 'listComponent'?webViewForm.wv_name:carForm.wv_name }}</span>
            <el-button type="primary" size="mini" @click="openWebList" >选择</el-button>
          </div>
        </el-form-item>
         <!--  -->
        <div v-show="comPonent==='listComponent'">
          <div v-for="(web,index) in webViewForm.init_data" :key="index" class="init-data-form">
            <el-form-item :label="'示例'+(index+1)"></el-form-item>
            <div class="del-model fz12" @click="delWebModel(index,1)">删除</div>
            <el-form-item class="up-item">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :http-request="(params) =>beforePictureUpload(params,index)"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload">
                <img v-if="web.face" :src="web.face" class="avatar">
                <div class="flex-cent avatar-uploader-icon" v-else>
                  <i class="el-icon-plus"></i>
                </div>
              </el-upload>
              <div class="span-item fz12">
                <div>建议图片尺寸：1080X362</div>
                <div>大小限制：1M</div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-input v-model="web.title" placeholder="  请输入标题"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="web.introduce" placeholder="  请输入概述"></el-input>
            </el-form-item>
            <el-form-item label="是否跳转">
              <div class="flex-bet mt8">
                <div></div>
                <el-switch active-value="1" inactive-value="0" v-model="web.is_jump"></el-switch>
              </div>
            </el-form-item>
            <el-form-item v-show="web.is_jump != 0">
              <div class="fz14" style="color: #606266;">跳转链接：</div>
              <el-input type="textarea" :rows="4" v-model="web.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
            </el-form-item>
            <el-form-item v-show="web.is_jump != 0">
              <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
              <el-input type="textarea" :rows="4" v-model="web.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
            </el-form-item>
          </div>
        </div>
        <div v-show="comPonent==='carComponent'">
          <div v-for="(car,index) in carForm.init_data" :key="index" class="init-car-form">
            <el-form-item :label="'图片'+(index+1)"></el-form-item>
            <div class="del-model fz12" @click="delWebModel(index,2)">删除</div>
            <el-form-item class="">
              <el-upload
                  class="avatar-uploader"
                  action=""
                  :http-request="(params) =>beforeCarUpload(params,index)"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload">
                <img v-if="car.face" :src="car.face" class="avatar">
                <div class="flex-cent avatar-uploader-icon" v-else>
                  <i class="el-icon-plus"></i>
                  <div class="fz12">
                    <div>建议图片尺寸：1080X362</div>
                    <div>大小限制：1M</div>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="是否跳转">
              <div class="flex-bet mt8">
                <div></div>
                <el-switch active-value="1" inactive-value="0" v-model="car.is_jump"></el-switch>
              </div>
            </el-form-item>
            <el-form-item v-show="car.is_jump==1">
              <div class="fz14" style="color: #606266;">跳转链接：</div>
              <el-input type="textarea" :rows="4" v-model="car.jump_url" placeholder="  请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
            </el-form-item>
          </div>
        </div>

      </el-form>
    </div>
    <!-- webview   选择数据源-->
    <div v-show="typeform.set_type===2 && iconType===4">
      <el-form ref="form" label-position='left'
               :model="webForm" class="right-form-webview"
      >
        <el-form-item label="选择数据源">
          <div class="flex-bet select-data">
            <span class="app-name-input">{{ webForm.data_source }}</span>
            <el-button type="primary" @click="chooseData()" size="mini" v-if="type_all == 1">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item label="webview窗口地址">
          <el-select v-model="webForm.field" placeholder="选择链接字段">
            <el-option
                v-for="item in webOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all==0"
                >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否跳转">
          <el-radio-group v-model="webForm.is_jump">
            <el-radio :label="0"  :disabled="type_all == 0">不用跳转</el-radio>
            <el-radio :label="1"  :disabled="type_all == 0">点击跳转（静态链接）</el-radio>
            <el-radio :label="2"  :disabled="type_all == 0">点击跳转（动态链接）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="webForm.is_jump===1">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="webForm.jump_url" placeholder=" 请输入点击跳转URL链接（必须包含http://或者https://）"></el-input>
        </el-form-item>
        <el-form-item v-show="webForm.is_jump===1">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-input type="textarea" :rows="4" v-model="webForm.pagepath" placeholder="  请输入小程序跳转URL链接"></el-input>
        </el-form-item>
        <el-form-item v-show="webForm.is_jump===2">
          <div class="fz14" style="color: #606266;">跳转链接：</div>
          <el-select v-model="webForm.jump_field" placeholder="请选择跳转链接字段">
            <el-option
                v-for="item in webOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all==0"
                >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="webForm.is_jump===2">
          <div class="fz14" style="color: #606266;">小程序跳转链接：</div>
          <el-select v-model="webForm.pagepath_field" placeholder="请选择小程序跳转链接字段">
            <el-option
                v-for="item in webOptions"  
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="type_all==0"
                >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>


    <div class="right-btns flex-bet" v-show="iconType!==0">
      <div class="right-btn defalt-btn" @click="clearForm">取消</div>
      <div @click="savePart" class="right-btn submit-btn">保存此模块</div>
    </div>

    <el-dialog title="选择应用" class="app-dialog" width="800px" :visible.sync="dialogApp">
      <div v-if="appList.length>0" class="app-list flex-start">
        <div class="app-item" v-for="(app,index) in appList" :key="index">
          <div v-if="app.isChoose" class="flex-start-col is-choose">
            <img :src="app.logo" alt="" class="app-cover">
            <div class="app-name fz18">{{ app.app_name }}</div>
          </div>
          <div v-else class="flex-start-col not-choose" @click="chooseItem(app)">
            <img :src="app.logo" alt="" class="app-cover">
            <div class="app-name fz18">{{ app.app_name }}</div>
          </div>
        </div>
      </div>
      <div v-else class="flex-cent-col">
        <img src="@/assets/images/noData.png" alt="暂无数据" style="margin:30px 0">
        <div>暂无应用</div>
      </div>
    </el-dialog>
    <el-dialog title="请选择数据源" class="data-dialog" width="560px" :visible.sync="dialogData">
      <div class="form-bg flex-cent">
        <el-form ref="dataForm" :model="dataForm" size="small" @submit.native.prevent>
          <el-form-item>
            <el-input @keyup.enter.native="dataSearch" @input="clearAll" v-model="dataForm.search"
                      placeholder="  请输入数据名称"></el-input>
            <i @click="dataSearch" class="el-icon-search search-btn" style="position: absolute;
        color: #C0C4CC;
        right: 10px;
        top: 10px;
        cursor: pointer;"></i>
          </el-form-item>
        </el-form>
      </div>
      <div class="data-list" v-if="dataList.length>0">
        <div class="data-item flex-bet" @click="dataSelect(index,data)"
             :class="selectDataIndex===index?'data-active':''" v-for="(data,index) in dataList" :key="index">
          <div>{{ data.api_name }}</div>
          <i class="iconfont icon-gou"></i>
        </div>
      </div>
      <div v-else class="flex-cent-col">
        <img src="@/assets/images/noData.png" alt="暂无数据" style="margin:30px 0">
        <div>暂无数据源</div>
      </div>
      <div class="data-btns flex-cent">
        <el-button @click="dialogData=false" class="cancel" size="mini">取消</el-button>
        <el-button @click="dispData" size="mini" type="primary">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择webview模板" class="web-dialog" width="670px" :visible.sync="dialogWeb">
      <div class="web-list flex-bet">
        <div class="app-item flex-start-col"  v-for="(app,index) in webViewList" :key="index">
          <div class="app-name fz18">{{ app.wv_name }}</div>
          <img :src="app.face" alt="" class="app-cover">
          <el-button @click="chooseWeb(app)" type="primary" size="mini">选择</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getAppList, sourceLists,getApilist, sourceList, webModelList, updataWebModel , getAgentLastWebview , insertModel} from '@/api/workbench'
import {getUploadToken} from '@/api/upload'
import {logOut} from "../api/workbench";

const COS = require('cos-js-sdk-v5');
export default {
  name: "NewModel",
  props:['apilistArr','pushList','modularIndex','tabType'],//tabType-0为自定义模块1为系统模块
  data() {
    return {
      apilist:{},// 当前系统模板数据
      comPonent: '',//webviewl类型
      pushIndex: 0,
      uploadResult: null,
      dialogApp: false,//应用列表弹窗
      dialogData: false,//数据源弹窗
      dialogWeb: false,//webview模板弹窗
      appList: [],//应用列表
      webViewList: [//webview列表

      ],
      access_token:'',
      topform: {
        modelName: '',
        appName: '',
        app_id: null,
      },
      typeform: {
        set_type: 1,
      },
      keyDataForm: [//keydata自定义静态页面表单
        {
          api: null,
          key: '',
          data: '',
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        },
        {
          api: null,
          key: '',
          data: null,
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        },
        {
          api: null,
          key: '',
          data: null,
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        },
        {
          api: null,
          key: '',
          data: null,
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        }
      ],
      keyModelForm: {
        api: null,
        key: '',
        data: null,
        is_jump: 0,
        modular_type: 'keydata',
        jump_url: '',
        pagepath: '',
        is_show: 1,
        set_type: 2,
        data_source: '',
        key_field: null,
        data_field: null,
        jump_field: '',
        pagepath_field: '',
        limit: 4,
      },
      imgForm: {
        api: '',
        url: '',
        set_type: 1,
        data_source: '',
        modular_type: 'image',
        field: '',
        is_jump: 0,
        jump_url: '',
        pagepath: '',
        jump_field: '',
        pagepath_field: '',
      },
      listForm: [
        {
          api: '',
          title: '',
          is_jump: 0,
          set_type: 1,
          data_source: '',
          modular_type: 'list',
          field: '',
          jump_url: '',
          pagepath: '',
          jump_field: '',
          pagepath_field: '',
        }
      ],
      listModelForm: {//list选择数据源
        api: '',
        title: '',
        is_jump: 0,
        set_type: 2,
        data_source: '',
        modular_type: 'list',
        field: '',
        jump_url: '',
        pagepath: '',
        jump_field: '',
        pagepath_field: '',
        limit: 3,
      },
      webForm: {
        api: 0,
        url: '',
        is_jump: 0,
        set_type: 1,
        data_source: '',
        modular_type: 'webview',
        field: '',
        jump_url: '',
        pagepath: '',
        jump_field: '',
        pagepath_field: '',
        webview_template: 0,
      },
      webViewForm: {
        id: '',
        face: '',
        wv_name: '',
        remarks: '',
        init_data: [],
        wv_type: '',
      },
      carForm: {
        id: '',
        face: '',
        init_data: [],
        web_url: '',
        wv_name: '',
        wv_type: '',
      },
      dataForm: {//数据源表单
        search: '',
      },
      dataList: [],//数据源
      selectDataIndex: -1,//选择数据源索引
      selectData: {},//选择数据源
      iconType: 0,
      sourceId: '',//数据源id
      iconModel: '',
      appIds: [],//已选择应用id列表
      openAppType: 1,
      jumpOptions: [],
      imgOptions: [],
      listOptions: [],
      webOptions: [],
      keyNumOptions: [
        {value: 1, label: 1},
        {value: 2, label: 2},
        {value: 3, label: 3},
        {value: 4, label: 4},
      ],
      listNumOptions: [
        {value: 1, label: 1},
        {value: 2, label: 2},
        {value: 3, label: 3},
      ],
      currentIndex: 0,
      tcVod: {},
      getTokenRes: {},
      webFormId: null,
      is_wx3:true,
      loading:false,//加载中
      saveType:'add',
      data_id_judge:'',//判断是新增还是修改是否有id
      type_all:1,//如果为空或者undefted是自定义模板否则为系统模块
    }
  },
  watch:{
    // 监听系统模板数据
    apilistArr:{
      handler(res){
        // if(res.id){
        //   this.apilist = res
        //   this.handleSys(res)
        // }else{
        //   this.apilist = {}
        // }
        if(!res.id){
            this.webViewForm.wv_name =''
            this.carForm.wv_name = ''
        }else{
          this.data_id_judge = res.id
          this.webFormId = res.id
        }
        this.handleSys(res)
      },
      immediate:true,
      deep:true
    },
    //监听推送对象为自定义模板、还是系统模板
    // tabType:{
    //   handler(newVal){
    //     if(newVal == 1 ){
    //       this.typeform.set_type = 2
    //     }
    //   },
    //   immediate: true,
    // }
  },
  components: {},
  beforeDestroy() {
    sessionStorage.removeItem('webList')
    sessionStorage.removeItem('appList')
  },
  created() {
    let isWx3=sessionStorage.getItem('is_wx3')
    this.is_wx3=isWx3;
    if (process.env.NODE_ENV === 'production') {
      if(isWx3==='true'){
        this.getToken()
      }
    }
  },
  methods: {
    // 处理系统模板数据
    handleSys(res){
      //判断是自定义还是系统模块
      this.type_all = res.type_all
      if(res){
        this.access_token = res.access_token
        if(res.type_all==0){
          switch(res.type){
            case 'list': // 名冠新闻公告
              this.typeform.set_type = 2
              this.listModelForm.api = res.api_id;
              this.listModelForm.data_source = res.api_name
              this.listModelForm.jump_field = 'jump_url'
              this.listModelForm.field = 'news_title'
              this.listModelForm.is_jump = 2
              this.listOptions = [] // 初始
              for (let key in res.field_get) {
                this.listOptions.push({
                  label: res.field_get[key] + '(' + key + ')',
                  value: key,
                  disabled: true
                })
              }
            break;
            case 'keydata': // 企微审批
              this.typeform.set_type = 2
              this.keyModelForm.api = res.api_id;
              this.keyModelForm.data_source = res.api_name
              this.keyModelForm.key_field = 'key'
              this.keyModelForm.data_field = 'data'
              this.keyModelForm.jump_field = 'jump_url'
              this.keyModelForm.pagepath_field = 'pagepath'
              this.keyModelForm.is_jump = 2

              this.jumpOptions = [] // 初始
              for (let key in res.field_get) {
                this.jumpOptions.push({
                  label: res.field_get[key] + '(' + key + ')',
                  value: key,
                  disabled: true
                })
              }
            break;
            case 'webview'  : // web-view -list
              if(res.wv_type == 'list'){
                this.typeform.set_type = 2
                this.webForm.api = res.api_id
                this.webForm.jump_field = res.content.jump_field
                this.webForm.field  = res.content.field
                this.webForm.pagepath = res.content.pagepath
                this.webForm.is_jump = res.content.is_jump
                this.webForm.data_source = res.content.data_source
                this.webForm.wv_type = res.wv_type
              }else{
                this.typeform.set_type =2
                this.webForm.api = res.api_id
                this.webForm.jump_field = res.content.jump_field
                this.webForm.field  = res.content.field
                this.webForm.pagepath = res.content.pagepath
                this.webForm.is_jump = res.content.is_jump
                this.webForm.data_source = res.content.data_source
                this.webForm.wv_type = res.wv_type
              }  
              this.webOptions = [] // 初始
              for (let key in res.field_get) {
                this.webOptions.push({
                  label: res.field_get[key] + '(' + key + ')',
                  value: key,
                  disabled: true
                })
              }
              this.webViewForm.wv_name =res.content.data_source
              this.webViewForm.wv_type =res.wv_type
              this.webViewForm.access_token =res.access_token
              this.webViewForm.api_type =res.api_type
           }  
        }else{
            this.webViewForm.api_type =res.api_type
            this.webViewForm.wv_type =res.wv_type
            this.webViewForm.access_token =res.access_token
        }
        this.$forceUpdate()
      }
    },
    setSelectType(type, icon, appids, pushIndex) {
      const that = this;
      this.pushIndex = pushIndex;
      this.appIds = appids;
      this.iconModel = type;
      this.topform.modelName = icon ? icon.modular_name : null;
      this.topform.app_id = icon ? icon.app_id : null;
      getAppList({is_id_key: 2}).then(res => {
        if (res.code === 200) {
          that.appList = res.data;
          that.appList.map(app => app.isChoose = that.appIds.some(item => item === app.id))
          if (icon?.app_id) {
            that.appList.forEach(item => {
              if (item.id === icon?.app_id) {
                that.topform.appName = item.app_name;
                that.topform.modelName = item.app_name;
              }
            })
          } else {
            that.topform.appName = '';
          }
        } 
      })
      this.selectDataIndex=-1
      this.selectData=''
      if (!icon) {
        this.clearForm()
      } else if(icon.api_type==4){
        this.jumpOptions = [] // 初始
      } else {
        getApilist({is_id_key: 2, type: type, field: 'id,api_name,field_get'}).then(res => {
          if (res.code === 200) {
            this.dataList = res.data;
            res.data.map((item, index) => {
              if (icon?.content[0]?.api == item.id) {
                this.selectDataIndex = index
                this.selectData = item;
              }
            })
            icon?.content[0]?.api ? icon.content[0].api : ''
          }
        })
      }
      if (type == 'keydata') {
        this.iconType = 1;
        this.typeform.set_type = icon ? icon.set_type : 3;
        if (icon?.content?.length > 0) {
          if (icon.set_type === 2) {//数据源
            icon.content[0].is_jump = parseInt(icon.content[0].is_jump)
            this.keyModelForm = icon.content[0];
          } else {//分开设置
            icon.content.map(item => {
              item.is_show = parseInt(item.is_show)
              item.set_type = parseInt(item.set_type)
              item.set_type1 = parseInt(item.set_type1)
              item.set_type2 = parseInt(item.set_type2)
              item.is_jump = parseInt(item.is_jump)
            })
            this.keyDataForm = icon.content;
          }
        }
      } else if (type == 'image') {
        this.iconType = 2;
        this.typeform.set_type = icon ? icon.set_type : 1;
        icon ? icon.content.length > 0 ? this.imgForm = icon.content[0] : '' : ''
        if (icon?.content?.length > 0) {
          that.imgForm.is_jump = icon.content[0].is_jump
        }
      } else if (type == 'list') {
        this.typeform.set_type = icon ? icon.set_type : 1;
        this.iconType = 3;
        if (icon?.content?.length > 0) {
          if(icon.set_type === 1){
            icon.content.map(item => {
              item.is_jump = item.is_jump
            })
            this.listForm = icon.content;

          }else{
            this.listModelForm = icon.content[0];
            this.listModelForm.is_jump=parseInt(icon.content[0].is_jump)
          }
        }
      } else {
        this.typeform.set_type = icon ? icon.set_type : 1;
        this.iconType = 4
        icon ? icon.content.length > 0 ? this.webForm = icon.content[0] : '' : ''
        if (icon?.content?.length > 0) {
          //判断为自定义模板还是系统模块
              that.webForm.is_jump = parseInt(icon.content[0].is_jump)
              webModelList().then(res => {
                if (res.code === 200) {
                  res.data.map(item => {
                    item.init_data.map(data=>{
                      data.is_jump=parseInt(data.is_jump)
                    })
                    if (icon.content[0].webview_template == item.id) {
                      this.comPonent = item.edit_url
                      item.edit_url === 'listComponent' ? that.webViewForm = item : that.carForm = item;
                      that.webViewForm.wv_type = item.wv_type
                      // this.webFormId = item.id;
                    }
                  })
                  this.webViewList = res.data;
                } else {
                  this.$message({
                    type: 'warning',
                    message: '未获取到数据'
                  })
                }
              })
        
        }
      }
      this.FngetAgentLastWebview()
    },
    getToken() {
      const data = {
        size: 3,
        mime: 'image',
        action_type: 'upload',
        type: 'tx'
      }
      let that = this;
      getUploadToken(data).then(res => {
        that.getTokenRes = res.data;
        that.tcVod = new COS({
          SecretId: that.getTokenRes.credentials.tmpSecretId,
          SecretKey: that.getTokenRes.credentials.tmpSecretKey,
          XCosSecurityToken: that.getTokenRes.credentials.sessionToken,
          StartTime: that.getTokenRes.startTime,
          ExpiredTime: that.getTokenRes.expiredTime,
        })
      })
    },
    httpRequest(file) {
      const that = this;
      if(this.is_wx3==='true'){
        this.tcVod.putObject({
          Bucket: this.getTokenRes.bucket,//存储桶名称
          Region: this.getTokenRes.region,//地域名字
          Key: this.getTokenRes.prefix + file.file.name,//文件名字
          Body: file.file,//上传对象
        }, function (err, data) {
          console.log(err || data)
          if (data?.statusCode === 200) {
            that.imgForm.url = 'http://' + data.Location
          } else {
            that.$message.error('上传失败,请重试')
          }
        })

      }else{
        let data = new FormData();
        data.append("image", file.file)
        this.$axios.post('/template/uploadImgLocation',data).then(res=>{
          this.$message({
            type:'success',
            message:res.message
          })
          that.imgForm.url = location.origin + res.data.path
          this.dialogLoad=false;
        }).catch(err=>{
          this.dialogLoad=false;
        })
      }

    },
    beforeAvatarUpload(file) {
      let isJPG = false;
      if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/bmp' || file.type === 'image/gif') {
        isJPG = true
      }
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.$message.error('上传图片仅限 jpg/png/bmp 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isJPG && isLt2M;
    },
    beforePictureUpload(file, index) {
      const that = this;
      if(this.is_wx3==='true'){
        this.tcVod.putObject({
          Bucket: this.getTokenRes.bucket,//存储桶名称
          Region: this.getTokenRes.region,//地域名字
          Key: this.getTokenRes.prefix + file.file.name,//文件名字
          Body: file.file,//上传对象
        }, function (err, data) {
          console.log(err || data)
          if (data?.statusCode === 200) {
            that.webViewForm.init_data[index].face = 'http://' + data.Location
          } else {
            that.$message.error('上传失败,请重试')
          }
        })
      }else{
        let data = new FormData();
        data.append("image", file.file)
        this.$axios.post('/template/uploadImgLocation',data).then(res=>{
          this.$message({
            type:'success',
            message:res.message
          })
          that.webViewForm.init_data[index].face = location.origin + res.data.path
          this.dialogLoad=false;
        }).catch(err=>{
          this.dialogLoad=false;
        })
      }

    },
    beforeCarUpload(file, index) {
      const that = this;
      if(this.is_wx3==='true'){
        this.tcVod.putObject({
          Bucket: this.getTokenRes.bucket,//存储桶名称
          Region: this.getTokenRes.region,//地域名字
          Key: this.getTokenRes.prefix + file.file.name,//文件名字
          Body: file.file,//上传对象
        }, function (err, data) {
          console.log(err || data)
          if (data?.statusCode === 200) {
            that.carForm.init_data[index].face = 'http://' + data.Location
          } else {
            that.$message.error('上传失败,请重试')
          }
        })
      }else{
        let data = new FormData();
        data.append("image", file.file)
        this.$axios.post('/template/uploadImgLocation',data).then(res=>{
          this.$message({
            type:'success',
            message:res.message
          })
          that.carForm.init_data[index].face = location.origin + res.data.path
          this.dialogLoad=false;
        }).catch(err=>{
          this.dialogLoad=false;
        })
      }


    },
    addModel() {//新增模板
      if (!this.iconModel) {
        this.$message({
          type: 'warning',
          message: '请先选择模板类型'
        })
        return false;
      }
      if (this.iconType === 1) {//keydata
        if (this.typeform.set_type === 3) {//分开设置
          if (this.keyDataForm.length < 4) {
            this.keyDataForm.push({
              api: null,
              key: '',
              data: null,
              is_jump: 0,
              modular_type: 'keydata',
              jump_url: '',
              pagepath: '',
              is_show: 1,
              set_type: 1,
              set_type1: 1,
              set_type2: 1,
              data_source: '',
              key_field: null,
              data_field: null,
              jump_field: '',
              pagepath_field: '',
            })
          } else {
            this.$message({
              type: 'warning',
              message: '最多添加四条数据'
            })
            return
          }

        } else {//数据源
          this.$message({
            type: 'warning',
            message: '最多添加一条数据'
          })
          return false
        }
      } else if (this.iconType === 2) {//image
        this.$message({
          type: 'warning',
          message: '最多添加一条数据'
        })
        return false
      } else if (this.iconType === 3) {//list
        if (this.typeform.set_type === 1) {//自定义静态页面
          if (this.listForm.length < 3) {
            this.listForm.push({
              api: '',
              title: '',
              is_jump: 0,
              set_type: 1,
              data_source: '',
              modular_type: 'list',
              field: '',
              jump_url: '',
              pagepath: '',
              jump_field: '',
              pagepath_field: '',
            })
          } else {
            this.$message({
              type: 'warning',
              message: '最多添加三条数据'
            })
            return false
          }
        } else {

          this.$message({
            type: 'warning',
            message: '最多添加一条数据'
          })
          return false
        }
      } else {//webview
        if (this.typeform.set_type === 1) {
          this.webViewForm.init_data.push({
            face: '',
            title: '',
            introduce: '',
            is_jump: 0,
            jump_url: '',
            pagepath: '',
          })
          this.carForm.init_data.push({
            face: '',
            title: '',
            introduce: '',
            is_jump: 0,
            jump_url: '',
          })
        } else {
          this.$message({
            type: 'warning',
            message: '最多添加一条数据'
          })
          return false
        }
      }


    },
    delLeftForm(index) {//删除模板
      if (this.listForm.length < 2) {
        this.$message({
          type: 'warning',
          message: '至少保留一个模板'
        })
        return false
      } else {
        this.listForm.splice(index, 1)
      }
    },
    openAppList() {
      if (this.pushIndex !== 0) {
        return false
      }
      if (this.iconModel) {
        this.dialogApp = true;
        this.getListApp();
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择模板类型'
        })
      }
    },
    openWebList() {
      if (!this.iconModel) {
        this.$message({
          type: 'warning',
          message: '请先选择模板类型'
        })
        return false;
      }
      if(this.topform.app_id == null){
         this.$message({
          type: 'warning',
          message: '请先选择应用'
        })
        return false;
      }
      this.getWebList()
    },
    getWebList() {
      webModelList().then(res => {
        if (res.code === 200) {
          this.dialogWeb = true;

          res.data.map(item=>{
            item.init_data.map(data=>{
              data.is_jump=parseInt(data.is_jump)
            })
          })
          this.webViewList=res.data;
        } else {
          this.$message({
            type: 'warning',
            message: '未获取到数据'
          })
        }
      }).catch(err => {
        console.log(err)
      })

    },
    getListApp() {
      if(!sessionStorage.getItem('appList')){
        getAppList({is_id_key: 2}).then(res => {
          if (res.code === 200) {
            this.appList = res.data;
            sessionStorage.setItem('appList',JSON.stringify(this.appList))
            this.appList.map(app => app.isChoose = this.appIds.some(item => item === app.id))
          } else {
            this.$message({
              type: 'warning',
              message: '未获取到数据'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }else{
        this.appList=JSON.parse(sessionStorage.getItem('appList'))
        this.appList.map(app => app.isChoose = this.appIds.some(item => item === app.id))
      }

    },
    chooseItem(app) {//选择应用
      var flag = true;
      this.appIds.map(item => {
        if (item === app.id) {
          this.$message({
            type: 'warning',
            message: '该应用已设置'
          })
          flag = false;
          return false;
        }
      })
      if (flag) {
        this.topform.app_id = app.id;
        this.topform.appName = app.app_name;
        this.topform.modelName = app.app_name;
        this.dialogApp = false;
      }
      this.FngetAgentLastWebview()
    },
    chooseWeb(app) {//webview模板
    if(app.edit_url == 'listComponent'){
        this.webViewForm = app
        if(app.init_data.length===0){
          this.webViewForm.init_data=[{
            face: '',
            title: '',
            introduce: '',
            is_jump: 0,
            jump_url: '',
            pagepath: '',
          }]
        }
      }else{
        this.carForm = app;
        if(app.init_data.length===0){
          this.carForm.init_data=[{
            face: '',
            title: '',
            introduce: '',
            is_jump: 0,
            jump_url: '',
          }]
        }
      }
      this.webFormId = app.id;
      this.webViewForm.wv_type = app.wv_type
      this.comPonent = app.edit_url;
      this.dialogWeb = false;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
    },
    // 获取应用webview的最近设置数据
    FngetAgentLastWebview(){
      let post_data = {
          agent_id:this.topform.app_id,//应用id
          wv_type:this.webViewForm.wv_type,//webview类型：list 列表型 carousel 轮播型
          template_id:this.$route.query.type !=3?this.$route.query.id:'',//模板id
        }
      //有可能为权限判断 --- 待考证
      if(this.topform.app_id != undefined && this.topform.app_id !=  null ){
        this.loading = true
        getAgentLastWebview(post_data).then(res => {
          if(res.code == 200){
            //如果查询无数据的话让他初始化空
            if(res.data.id != undefined){
               this.saveType = 'modify'
               if(res.data.edit_url == 'listComponent'){
                  this.webViewForm = res.data
                  if(res.data.init_data.length===0){
                      this.webViewForm.init_data=[{
                        face: '',
                        title: '',
                        introduce: '',
                        is_jump: 0,
                        jump_url: '',
                        pagepath: '',
                      }]
                    }
                  }else{
                    this.carForm = res.data;
                    if(res.data.init_data.length===0){
                      this.carForm.init_data=[{
                        face: '',
                        title: '',
                        introduce: '',
                        is_jump: 0,
                        jump_url: '',
                      }]
                    }
                  }
                  this.webFormId = res.data.id;
                  this.webViewForm.wv_type = res.data.wv_type
                  this.comPonent = res.data.edit_url;
            }else{
              this.saveType = 'add'
              this.comPonent = ''
              if(JSON.stringify(res.data) != "{}" && JSON.stringify(res.data) != "[]"){
                if(res.data.edit_url == 'listComponent'){
                  this.webViewForm = res.data
                  if(res.data.init_data.length===0){
                        this.webViewForm.init_data=[{
                          face: '',
                          title: '',
                          introduce: '',
                          is_jump: 0,
                          jump_url: '',
                          pagepath: '',
                        }]
                      }
                    }else{
                      this.carForm = res.data;
                      if(res.data.init_data.length===0){
                        this.carForm.init_data=[{
                          face: '',
                          title: '',
                          introduce: '',
                          is_jump: 0,
                          jump_url: '',
                        }]
                      }
                    }
                    this.webViewForm.wv_type = res.data.wv_type
                    this.comPonent = res.data.edit_url;
                }else{
                  this.webViewForm.init_data=[{
                        face: '',
                        title: '',
                        introduce: '',
                        is_jump: 0,
                        jump_url: '',
                        pagepath: '',
                    }]
                    this.carForm.init_data=[{
                        face: '',
                        title: '',
                        introduce: '',
                        is_jump: 0,
                        jump_url: '',
                      }]
                      //id为空时判断为新增
                      this.webViewForm.id = ''
                      this.carForm.id = ''
                  } 
              }
            // 初始化
            if(this.pushList[0].modulars[this.modularIndex].content[0] != undefined){
              this.webViewForm.url = this.pushList[0].modulars[this.modularIndex].content[0].url
              this.webViewForm.jump_url = this.pushList[0].modulars[this.modularIndex].content[0].jump_url
            }
            this.dialogWeb = false;
           }
        })
        this.loading = false
      }else{
        // this.saveType = 'add'
      }
    },
    dataSearch() {//搜索数据源
      let keyword = this.dataForm.search.trim();
      if (keyword.length < 2) {
        this.$message({
          message: '请输入至少两位关键字',
          type: 'warning'
        })
        return false;
      }
      this.dataList = [];
      sourceList({page: 1, page_size: 100, api_name: this.dataForm.search}).then(res => {
        if (res.code === 200) {
          let data = res.data.data;
          data.map(item => {
            this.dataList.push({
              id: item.id,
              api_name: item.api_name
            });
          })
        } else {
          this.$message({
            message: '获取数据失败，请稍后再试',
            type: 'warning'
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    clearAll() {//清空搜索框
      if (!this.dataForm.search) {
        this.getSourceList(this.iconModel)
      }
    },
    chooseData(index) {//选择数据
      if(this.apilist.id){ return }
      const that=this;
      this.currentIndex = index;
      if (this.topform.appid == null ) {
        this.getSourceList(this.iconModel).then(_=>{
          that.dialogData = true;
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择模板类型'
        })
      }
    },
    getSourceList(type) {
      return getApilist({is_id_key: 2, type: type, field: 'id,api_name,field_get'}).then(res => {
        if (res.code === 200) {
          this.dataList = res.data;
        } else {
          this.$message({
            type: 'warning',
            message: '未获取到数据'
          })
        }
      }).catch(err => {
        console.log(err)
      })

    },
    dataSelect(index, data) {//勾选数据
      this.selectDataIndex = index;
      this.selectData = data;
    },
    dispData() {//确定选择数据
      this.jumpOptions = [];
      this.imgOptions = [];
      this.listOptions = [];
      this.webOptions = [];
      const thisIconType = this.iconModel;
      if (thisIconType == 'keydata') {
        if (this.typeform.set_type === 3) {
          this.keyDataForm[this.currentIndex].data_source = this.selectData.api_name;
          this.keyDataForm[this.currentIndex].api = this.selectData.id;
        } else {
          this.keyModelForm.data_source = this.selectData.api_name;
          this.keyModelForm.api = this.selectData.id;
        }
        for (let key in this.selectData.field_get) {
          this.jumpOptions.push({
            label: this.selectData.field_get[key] + '(' + key + ')',
            value: key,
          })
        }
      } else if (thisIconType == 'image') {
        this.imgForm.data_source = this.selectData.api_name;
        this.imgForm.api = this.selectData.id;
        for (let key in this.selectData.field_get) {
          this.imgOptions.push({
            label: this.selectData.field_get[key] + '(' + key + ')',
            value: key,
          })
        }
      } else if (thisIconType == 'list') {
        this.listModelForm.data_source = this.selectData.api_name;
        this.listModelForm.api = this.selectData.id;
        for (let key in this.selectData.field_get) {
          this.listOptions.push({
            label: this.selectData.field_get[key] + '(' + key + ')',
            value: key,
          })
        }
      } else {//webview
        this.webForm.data_source = this.selectData.api_name;
        this.webForm.api = this.selectData.id;
        for (let key in this.selectData.field_get) {
          this.webOptions.push({
            label: this.selectData.field_get[key] + '(' + key + ')',
            value: key,
          })
        }
      }
      this.dialogData = false;
    },
    clearForm() {
      this.selectDataIndex=-1
      this.selectData='';
      this.topform = {
        modelName: '',
        appName: '',
        app_id: null,
      }
      this.keyDataForm= [//keydata自定义静态页面表单
        {
          api: null,
          key: '',
          data: '',
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        },
        {
          api: null,
          key: '',
          data: '',
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        },
        {
          api: null,
          key: '',
          data: '',
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        },
        {
          api: null,
          key: '',
          data: '',
          is_jump: 0,
          modular_type: 'keydata',
          jump_url: '',
          pagepath: '',
          is_show: 1,
          set_type: 1,
          set_type1: 1,
          set_type2: 1,
          data_source: '',
          key_field: null,
          data_field: null,
          jump_field: '',
          pagepath_field: '',
        }
      ]
      this.keyModelForm={
        api: null,
        key: '',
        data: null,
        is_jump: 0,
        modular_type: 'keydata',
        jump_url: '',
        pagepath: '',
        is_show: 1,
        set_type: 2,
        data_source: '',
        key_field: null,
        data_field: null,
        jump_field: '',
        pagepath_field: '',
        limit: 4,
      }
      this.imgForm={
        api: '',
        url: '',
        set_type: 1,
        data_source: '',
        modular_type: 'image',
        field: '',
        is_jump: 0,
        jump_url: '',
        pagepath: '',
        jump_field: '',
        pagepath_field: '',
      }
      this.listForm=[
        {
          api: '',
          title: '',
          is_jump: 0,
          set_type: 1,
          data_source: '',
          modular_type: 'list',
          field: '',
          jump_url: '',
          pagepath: '',
          jump_field: '',
          pagepath_field: '',
        }
      ]
      this.listModelForm={
        api: '',
        title: '',
        is_jump: 0,
        set_type: 2,
        data_source: '',
        modular_type: 'list',
        field: '',
        jump_url: '',
        pagepath: '',
        jump_field: '',
        pagepath_field: '',
        limit: 3,
      }
      this.webForm={
        api: 0,
        url: '',
        is_jump: 0,
        set_type: 1,
        data_source: '',
        modular_type: 'webview',
        field: '',
        jump_url: '',
        pagepath: '',
        jump_field: '',
        pagepath_field: '',
        webview_template: 0,
        wx_type:''
      }
      this.webViewForm={
        id: '',
        face: '',
        wv_name: '',
        remarks: '',
        init_data: [],
        wv_type: '',
      }
      this.carForm={
        id: '',
        face: '',
        init_data: [],
        web_url:'',
        wv_name:'',
        wv_type: '',
      }
    },
    savePart() {//保存此模块
      //liuleyigekeng,chongxieba
      if (!this.iconModel) {
        this.$message({
          type: 'warning',
          message: '请先选择模板类型'
        })
        return false;
      }
      if (!this.topform.app_id) {
        this.$message({
          type: 'warning',
          message: '请选择设置应用'
        })
        return false;
      }
      const that = this;
      const thisModular = {
        content: [],
      };
      thisModular.access_token = this.access_token
      thisModular.modular_name = this.topform.modelName;
      thisModular.app_id = this.topform.app_id;
      const thisSetType = this.typeform.set_type
      thisModular.set_type = thisSetType;
      const thisIconType = this.iconModel;
      thisModular.modular_type = thisIconType;
      if (thisIconType == 'keydata') {
        // if(item.is_jump===2){
        //   item.jump_url!=='' ? keydataFlag=true : keydataFlag=false
        // }
        if(thisSetType === 3){//自定义
          let keydataFlag=false,secondFlag=false,thirdFlag=false;
          this.keyDataForm.map((item, index) => {
            if(item.is_show===1){
              if(item.set_type1===1){
                item.key ? keydataFlag=true : keydataFlag=false
              }else{
                item.key_field ? keydataFlag=true : keydataFlag=false
              }
              if(item.set_type2===1){
                item.data ? secondFlag=true : secondFlag=false
              }else{
                item.data_field ? secondFlag=true : secondFlag=false
              }
              if(item.is_jump==1){
                item.jump_url ? thirdFlag=true : thirdFlag=false
              }else{
                thirdFlag=true
              }
            }
            if (item.set_type1 === 2 || item.set_type2 === 2) {
              this.keyDataForm[index].set_type = 2
            }
          })
          // return
          // if(keydataFlag){
          //   thisModular.content = this.keyDataForm
          // }else{
          //   this.$message({
          //     type:'warning',
          //     message:'请正确填写表单'
          //   })
          //   return false;
          // }
          thisModular.content = this.keyDataForm
        }else{
          if(!this.keyModelForm.api){
            this.$message({
              type:'warning',
              message:'请选择数据源'
            })
            return false;
          }
          if(!this.keyModelForm.key_field && !this.keyModelForm.data_field){
            this.$message({
              type:'warning',
              message:'请选择名称及统计字段'
            })
            return false;
          }
          if(this.keyModelForm.is_jump==2){
            if(!this.keyModelForm.jump_field){
              this.$message({
                type:'warning',
                message:'请选择跳转字段'
              })
              return false;
            }
          }
          if(this.keyModelForm.is_jump==1){
            if(!this.keyModelForm.jump_url){
              this.$message({
                type:'warning',
                message:'请填写跳转链接'
              })
              return false;
            }
          }
          thisModular.content.push(this.keyModelForm)

        }
      } else if (thisIconType == 'image') {
        this.imgForm.set_type = thisSetType;
        thisModular.content.push(this.imgForm);
      } else if (thisIconType == 'list') {
        thisSetType === 1 ? thisModular.content = this.listForm : thisModular.content.push(this.listModelForm);
      } else {//webview

        if (thisSetType !== 2 || this.type_all == 0) {
          let data = {
            id: this.webFormId,
            init_data: [],
          };
           //判断是自定义模板还是系统模板
            if(this.type_all == 1){
              if (this.comPonent === 'listComponent') {
                data.init_data = this.webViewForm.init_data
                data.wv_name = this.webViewForm.wv_name
                data.wv_type =this.webViewForm.wv_type
                data.status = this.webViewForm.status
                if(this.type_all ==1 && this.typeform.set_type ===1){
                  for(let i= 0;i<data.init_data.length;i++){
                      if(data.init_data[i].title == '' || data.init_data[i].face == ''){
                        this.$message({type:'warning',message:`请完善示例${i+1}内容`})
                        return false;
                      }
                  }
                }
              } else {
                  data.init_data =  this.carForm.init_data
                  data.wv_name = this.carForm.wv_name
                  data.wv_type = this.carForm.wv_type
                  data.status = this.carForm.status
                  if(this.type_all ==1  && this.typeform.set_type ===1){
                      for(let i= 0;i<data.init_data.length;i++){
                          if(data.init_data[i].face == ''){
                            this.$message({type:'warning',message:`请完善图片${i+1}内容`})
                            return false;
                        }
                    }
                  }
              }
            }else{
              data.init_data = [{
                  api:this.webForm.api,
                  jump_field:this.webForm.jump_field,
                  field:this.webForm.field,
                  pagepath:this.webForm.pagepath,
                  is_jump:this.webForm.is_jump,
                  data_source:this.webForm.data_source
              }]
              data.wv_name = this.webViewForm.wv_name
              data.wv_type =this.webViewForm.wv_type
              data.status = this.webViewForm.status
              if(data.wv_name == undefined){
                 this.$message({type:'warning',message:`请先选择模板类型`})
                 return false
              }
            }
          // 判断是修改还是保存
          //为新建
          // || (this.data_id_judge != ''&& this.data_id_judge != undefined)
          if(this.saveType != 'add' ){
            //为修改模板
            // if(this.pushList[0].modulars[this.modularIndex].app_id != null){
                updataWebModel(data).then(res => {
                  if(res.code == 200){
                    this.data_id = res.data.id
                    if(this.webViewForm.web_url != undefined){
                        this.webForm.url = this.comPonent === 'listComponent' ? this.webViewForm.web_url : this.carForm.web_url;
                        this.webForm.jump_url = this.comPonent === 'listComponent' ? this.webViewForm.web_url : this.carForm.web_url;
                    }
                    // this.webForm.is_jump = 1;
                    this.webForm.webview_template = this.webFormId;
                    // 有id则为修改
                    thisModular.data_id = this.data_id
                    this.$message({
                      type: 'success',
                      message: res.message,
                    })
                  }else{
                    this.$message({
                      type: 'warning',
                      message: res.message,
                    })
                  }
                }).catch(err => {
                  console.log(err)
                })
          }else{
                //模板新增
                data.agent_id = this.topform.app_id
                data.set_type = thisSetType
                if(this.$route.query.id != null){
                    data.template_id = this.$route.query.id//模板id
                }
                // delete data.face
                delete data.id
                insertModel(data).then(res => {
                  if(res.code == 200){
                      this.data_id = res.data.id
                      this.webForm.url = this.comPonent === 'listComponent' ? this.webViewForm.web_url : this.carForm.web_url;
                      this.webForm.jump_url = this.comPonent === 'listComponent' ? this.webViewForm.web_url : this.carForm.web_url;
                      // this.webForm.is_jump = 1;
                      this.webForm.webview_template = this.webFormId;
                      this.$message({
                          type: 'success',
                          message: res.message,
                      })
                      this.saveType = 'modify'
                      // 有id则为修改
                      thisModular.data_id = this.data_id
                  }else{
                     this.$message({
                      type: 'warning',
                      message: res.message,
                    })
                  }
             
                  
                }).catch(err => {
                    console.log(err)
                })
          }
        }
        this.webForm.set_type = thisSetType;
        //给webview类型传输的时候加
        // thisModular.wv_type =  this.comPonent === 'listComponent' ?this.webViewForm.wv_type:this.carForm.wv_type;
        thisModular.wv_type =  this.webViewForm.wv_type
        thisModular.access_token = this.webViewForm.access_token;
        thisModular.api_type = this.webViewForm.api_type;
        thisModular.content.push(this.webForm)
      }
      setTimeout(()=>{
          let is_url = true
          thisModular.content.map((res)=>{
              if(res.jump_url!=''&& res.jump_url != undefined &&  res.is_jump == 1 &&  !this.is_http(res.jump_url)){
                is_url = false
                return this.$message({
                  type:'warning',
                  message:'跳转链接必须包含http://或https://'
                })
              }else{
                is_url = true
              }
                res.webview_template = this.data_id
            })
            if(!is_url){ return false }
            that.$emit('setDetail', thisModular)
            if(this.iconModel != 'webview' || (thisSetType === 2 && this.type_all != 0)){
                this.$message({
                    type: 'success',
                    message: '保存模块成功',
                })
            }
        },1000)
    },
    delayFn(){
      
    },
    delWebModel(index, type) {
      let form = '';
      type === 1 ? form = this.webViewForm : form = this.carForm
      if (form.init_data.length < 2) {
        this.$message({
          type: 'warning',
          message: '至少保留一个模板'
        })
        return false
      } else {
        form.init_data.splice(index, 1)
      }
    },
    // 判断链接是否有http
    is_http(url){
      var Expression=/^https?:\/\//i;
      var objExp=new RegExp(Expression);
      if(objExp.test(url)){
        return true
      }else{
        return false
      }
		},
  }
}
</script>

<style scoped lang="less">
.new-model {
  height: calc(100vh - 80px);
  padding-bottom: 60px;
  //padding-top: 64px;
  overflow-y: auto;

  .right-title {
    padding: 0 20px;
    height: 50px;
    background: #F1F5F9;
    //position: fixed;
    //top: 64px;
    width: 320px;
    //z-index: 99;

    .el-button {
      font-size: 14px;
    }
  }

  .top-form {
    padding: 10px 20px 0;
    border-bottom: 2px solid #E0E0E0;

    .el-form-item:first-child {
      border-bottom: 1px solid #F5F5F5;
    }

    .app-label-name {
      width: 80px;
    }

    .cart {
      width: 180px;
    }

    .app-name-input {
      margin-left: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    /deep/ .el-input__inner, /deep/ .el-textarea__inner {
      border: none;
      padding: 0;
      background: #FCFCFC;
      resize: none;
    }
  }

  .mid-form {
    margin: 10px 20px 0;
    border-bottom: 1px solid #E0E0E0;

    .el-radio {
      margin-right: 22px;
    }
  }

  .right-form {
    padding: 10px 20px;

    .del-model {
      text-align: right;
      cursor: pointer;
      color: #B7B7B7;
    }

    .el-form-item:not(:last-child) {
      margin-bottom: 10px;
    }

    .app-name-input {
      margin-left: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    /deep/ .el-input__inner {
      border: none;
      padding: 0;
      background: #FCFCFC;
      resize: none;
    }

    /deep/ .el-textarea__inner {
      padding: 0;
      resize: none;
    }

    /deep/ .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      height: 84px;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      color: #8c939d;
      width: 280px;
      height: 84px;
      text-align: left;
      line-height: 20px;

      .el-icon-plus {
        font-size: 38px;
        margin-right: 20px;
      }
    }

    .avatar {
      width: 280px;
      height: 84px;
      display: block;
      object-fit: contain
    }
  }

  .right-form-list {
    padding: 10px 20px;
    position: relative;
    border-bottom: 1px solid #DBDBDB;

    .del-model {
      text-align: right;
      cursor: pointer;
      color: #B7B7B7;
      position: absolute;
      top: 18px;
      right: 23px;
      z-index: 77;
    }

    .el-form-item:not(:last-child) {
      margin-bottom: 10px;
    }

    .app-name-input {
      margin-left: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    /deep/ .el-input__inner {
      border: none;
      padding: 0;
      background: #FCFCFC;
      resize: none;
    }

    /deep/ .el-textarea__inner {
      padding: 0;
      resize: none;
    }
  }

  .right-form-last {
    padding: 10px 20px;

    .del-model {
      text-align: right;
      cursor: pointer;
      color: #B7B7B7;
      margin-bottom: 15px;
    }

    .app-name-input {
      margin-left: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .el-radio {
      margin-top: 10px;
    }

    /deep/ .el-textarea__inner {
      padding: 0;
      resize: none;
    }
  }

  .right-form-keyData {
    padding: 10px 20px;
    border-bottom: 1px solid #DBDBDB;

    .del-model {
      text-align: right;
      cursor: pointer;
      color: #B7B7B7;
    }

    .el-form-item:not(:last-child) {
      margin-bottom: 10px;
    }

    .app-name-input {
      margin-left: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .float-r{
      /deep/.el-form-item__label{
        font-weight: bold;
      }
    }

    .float-r .el-switch {
      float: right;
      padding: 20px 0;
    }

    .read-num {
      border-top: 1px solid #DBDBDB;
    }

    .radio-top {
      .el-radio {
        margin-right: 5px;

        .el-radio__label {
          padding-left: 5px;
        }
      }
    }

    .radio-midden {
      .el-radio {
        margin-top: 10px;
      }
    }

    .full-select {
      width: 100%;
    }

    /deep/ .no-border .el-input__inner {
      border: none;
      padding: 0;
      background: #FCFCFC;
      resize: none;
    }

    /deep/ .el-textarea__inner {
      padding: 0;
      resize: none;
    }
  }

  .right-form-webview {
    padding: 10px 20px;

    .init-data-form {
      position: relative;

      .del-model {
        position: absolute;
        top: 10px;
        right: 13px;
        text-align: right;
        cursor: pointer;
        color: #B7B7B7;
      }

      /deep/ .avatar-uploader {
        height: 45px;
      }

      /deep/ .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        height: 45px;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        color: #8c939d;
        width: 45px;
        height: 45px;
        text-align: left;
        line-height: 45px;

        .el-icon-plus {
          font-size: 38px;
        }
      }

      .avatar {
        width: 45px;
        height: 45px;
        display: block;
        object-fit: contain
      }
    }

    .init-car-form {
      position: relative;

      .del-model {
        position: absolute;
        top: 10px;
        right: 13px;
        text-align: right;
        cursor: pointer;
        color: #B7B7B7;
      }

      /deep/ .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        height: 84px;
        position: relative;
        overflow: hidden;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        color: #8c939d;
        width: 280px;
        height: 84px;
        text-align: left;
        line-height: 20px;

        .el-icon-plus {
          font-size: 38px;
          margin-right: 20px;
        }
      }

      .avatar {
        width: 280px;
        height: 84px;
        display: block;
        object-fit: contain
      }
    }


    .el-form-item:not(:last-child) {
      margin-bottom: 10px;
    }

    .app-name-input {
      margin-left: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .el-radio {
      margin-top: 10px;
    }

    /deep/ .up-item {
      .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .span-item {
      margin-left: 12px;

      div {
        line-height: 18px;
      }

      color: #909499;
    }


  }

  .right-btns {
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 319px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #fff;
    border-top: 1px solid #DBDBDB;
    cursor: pointer;

    .right-btn {
      width: 50%;
      height: 100%;
    }

    .submit-btn {
      color: #fff;
      background: #0082ef;
    }
  }
}

.new-model::-webkit-scrollbar {
  width: 0;
}

/deep/ .table-scope {
  .el-switch__label--left {
    position: relative;
    left: 58px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__core {
    width: 52px !important;
  }

  .el-switch__label--right {
    position: relative;
    right: 57px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__label--right.is-active {
    z-index: 1111;
    color: #fff !important;
  }

  .el-switch__label--left.is-active {
    z-index: 1111;
    color: #9c9c9c !important;
  }


}

.app-dialog {
  /deep/ .el-dialog__header {
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    border-bottom: 1px solid #F5F5F5;

    /deep/ .el-dialog__title {
      font-size: 16px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 20px;
    max-height: 463px;
    overflow-y: auto;

    .app-list {
      flex-wrap: wrap;
      padding: 0 6px;

      .app-item {

        .app-cover {
          margin-top: 33px;
          width: 72px;
          height: 72px;
          border-radius: 4px;
        }

        .app-name {
          width: 108px;
          padding-top: 24px;
          text-align: center;
        }

        .is-choose {
          width: 152px;
          height: 187px;
          margin: 16px;
          cursor: not-allowed;
          border: 1px solid #F5F5F5;
          border-radius: 4px;
          color: #ccc;
        }

        .not-choose {
          width: 152px;
          height: 187px;
          margin: 16px;
          border: 1px solid #F5F5F5;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            margin-top: -10px;
            box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
          }
        }
      }
    }
  }
}
.web-dialog{
  /deep/ .el-dialog__header {
    font-size: 15px;
    font-weight: 500;
    padding: 10px 20px;
    border-bottom: 1px solid #F5F5F5;

    /deep/ .el-dialog__title {
      font-size: 16px;
    }
  }

  /deep/ .el-dialog__body {
    .web-list {
      .app-item {
        width: 278px;
        .app-cover {
          width: 278px;
          margin-bottom: 16px;
        }
        .app-name {
          width: 108px;
          font-weight: bold;
          padding-bottom: 12px;
          text-align: center;
        }

      }
    }
  }
}
.data-dialog {
  /deep/ .el-dialog__header {
    font-weight: 500;
    padding: 8px 20px;

    /deep/ .el-dialog__title {
      font-size: 16px;
    }
  }

  /deep/ .el-dialog__body {
    padding: 0;

    .form-bg {
      width: 560px;
      height: 100px;
      background: url('~@/assets/images/form_bg.png') center center no-repeat;
    }

    /deep/ .el-form {
      width: 280px;
      position: relative;

      .el-form-item {
        margin-bottom: 0;
      }

      //.search-btn {
      //  position: absolute;
      //  color: #C0C4CC;
      //  right: 10px;
      //  top: 10px;
      //  cursor: pointer;
      //}
    }

    .data-list {
      padding: 20px;
      max-height: 320px;
      overflow-y: auto;

      .data-item {
        width: 100%;
        height: 48px;
        cursor: pointer;
        padding: 0 20px;

        .icon-gou {
          color: #3296FA;
          font-size: 22px;
          display: none;
        }
      }

      .data-item:not(:last-child) {
        border-bottom: 1px solid #F5F5F5;
      }

      .data-active {
        background: rgba(168, 212, 255, 0.1);

        .icon-gou {
          display: inline-block;
        }
      }
    }

    .data-list::-webkit-scrollbar {
      width: 0;
    }

    .data-btns {
      padding: 20px;

      .cancel {
        border-color: #0082EF;
        color: #0082EF;
      }
    }
  }
}

</style>